// #region Functions
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import us from "../assets/images/languageConversion/us.svg";
import de from "../assets/images/languageConversion/de.svg";
import it from "../assets/images/languageConversion/it.svg";
import { updateUserLanguage } from "../Redux/Api/fetch.api";
const { Option } = Select;
// #endregion

// #region LanguageSwitcher
const LanguageSwitcher = ({ language }) => {

    // #region Global variables
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default to English
    // #endregion

    // #region useEffect / Hooks
    // Update the selected language state and i18n language on prop change
    useEffect(() => {
        const initialLanguage = mapLanguageToCode(language);
        setSelectedLanguage(initialLanguage);
        i18n.changeLanguage(initialLanguage);
    }, [language, i18n]); // Only run this effect when `language` prop changes
    // #endregion

    // #region Functions
    // Map language prop to the corresponding i18n language code
    const mapLanguageToCode = (language) => {
        switch (language) {
            case 1: return "en"; // English
            case 2: return "de"; // German
            case 3: return "it"; // Italian
            default: return "en"; // Default to English
        }
    };

    const mapCodeToLanguage = (code) => {
        switch (code) {
            case "en": return 1; // English
            case "de": return 2; // German
            case "it": return 3; // Italian
            default: return 1; // Default to English
        }
    };

    // Update Language / API Calling 
    const updateLanguage = async (langage) => {
        // Prepare the payload for the API request based on the eventItem object
        const payload = {
            UserId: localStorage.getItem('UserId'),
            Language: langage
        };

        try {
            await updateUserLanguage(payload);
        } catch (err) {
            // Log the error and display an error toast notification
            console.error("Error updating language:", err);
        }
    };
    // #endregion

    // #region Evnets
    // Function to handle language change in the Select component
    const handleChange = async (value) => {
        setSelectedLanguage(value);
        i18n.changeLanguage(value);

        // Update into the database
        await updateLanguage(mapCodeToLanguage(value));
    };
    // #endregion   

    return (
        <Select
            className="language-select"
            value={selectedLanguage} // Use local state to manage selected language
            onChange={handleChange}
            dropdownRender={(menu) => <>{menu}</>}
        >
            <Option value="en">
                <span>
                    <img src={us} width={12} alt="English" style={{ marginRight: 8 }} />
                    English
                </span>
            </Option>

            <Option value="it">
                <span>
                    <img src={it} width={12} alt="Italian" style={{ marginRight: 8 }} />
                    Italian
                </span>
            </Option>

            <Option value="de">
                <span>
                    <img src={de} width={12} alt="German" style={{ marginRight: 8 }} />
                    German
                </span>
            </Option>
        </Select>
    );
};

export default LanguageSwitcher;

// #endregion