import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import { Tooltip } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllActivityLogs, fetchProjectDetails, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getalllogs } from '../../Redux/Actions/UserActivityAction';
import { getallprojects } from '../../Redux/Actions/AccessibilityActions';
import Refresh from "../../assets/images/refresh.svg";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";

const UserLogs = () => {
    const { t } = useTranslation('activityLogs');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;  
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [pageSize, setPagesize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [username, setUserName] = useState("")
    const [companyname, setCompanyName] = useState("")
    const [sitename, setSiteName] = useState("")
    const [appid, setAppId] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(5)
    let history = useHistory();
    const showPagination = totalCount > REACT_APP_DEFAULT_PAGESIZE;
    const [dropdownChange, setDropdownChange] = useState(false);
    const [allAppData, setAllAppData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [roleName, setRoleName] = useState(null);
    const loggedUserId = localStorage.getItem('UserId')  



    useEffect(() => {      
        getRoleDetails();
        getallapps();
    }, [])

    useEffect(() => {
        getAllActivityLogs(true)
    }, [refreshData])

    const getAllActivityLogs = (isReset = false, pageindex = 1, pagesize = REACT_APP_DEFAULT_PAGESIZE) => {        
        setLoading(true)
        fetchAllActivityLogs({
            PageIndex: pageindex,
            PageSize: pageSize > REACT_APP_DEFAULT_PAGESIZE ? pageSize : pagesize,
            UserName: isReset ? "" : username,
            CompanyName: isReset ? "" : companyname,
            SiteName: isReset ? "" : sitename,
            AppId: isReset ? null : appid            
        })
            .then(response => {               
                dispatch(getalllogs(response.data))
                setTable(response.data.data)
                setTotalCount(response.data.count);
                setLoading(false)
            })
       
            .catch((err) => {
                console.log("Err: ", err);
            });
        handleCancel();       
    }

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
               
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handlePageChange = (page, newPageSize) => {
        setCurrentPage(page);
        setPagesize(newPageSize);
        getAllActivityLogs(false, page, newPageSize)
    };

    const getallapps = () => {
       
        setLoading(true)
        fetchProjectDetails()
            .then(response => {
                
                dispatch(getallprojects(response.data))
                setAllAppData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getFullDate = (date) => {       
        const newDate = new Date(date);
        const returnDate = ((newDate.getMonth() > 8) ? (newDate.getMonth() + 1) : ('0' + (newDate.getMonth() + 1))) + '-' + ((newDate.getDate() > 9) ? newDate.getDate() : ('0' + newDate.getDate())) + '-' + newDate.getFullYear();
        const dateAndTime = date.split("T");
        const time = dateAndTime[1].split(":");
        const seconds = parseInt(time[2]);
        const newtime = time[0] + " : " + time[1] + " : " + seconds;
        const times = time[0] > 12 ? time[0] % 12 : time[0];
        const timeslatest = times < 9 ? ('0' + times) : times;
        const newtime1 = timeslatest + " : " + time[1] + " : " + seconds;
        if (time[0] && time[0] >= 12) {
            return (
                //dateAndTime[0].split("-").reverse().join("-") + ", " + newtime1 + " PM "
                returnDate + ", " + newtime1 + " PM "
            );
        } else {
            return (
                //dateAndTime[0].split("-").reverse().join("-") + ", " + newtime + " AM "
                returnDate + ", " + newtime + " AM "
            );
        }
    };

    const SearchControl = async () => {
              
        getAllActivityLogs();
    }

    const handleCancel = () => {
        setDropdownChange(true);
        setTimeout(() => {
            setDropdownChange(false);
        }, 0);
    };

    const handleSearchClear = () => {
        
        setUserName('');
        setCompanyName('');    
        setSiteName('');
        setAppId(null);
        setRefreshData(prev => !prev); 
        handleCancel();
      
       
    };

    const refreshLogs = () => {    
        setUserName('');
        setCompanyName('');
        setSiteName('');
        setAppId(null);
        setRefreshData(prev => !prev);       
    };


    const columns = [

        {            
             title: t("SrNo"),
             dataIndex: "",
             render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,        
        },

        {
            title: t("UserName"),
            dataIndex: "userName",
            //className: "names-col"

        },

        {
            title: t("Company(Site)"),
            dataIndex: "companyName",
           // className: "company-col",
             render: (value, item, index) =>
            (<>
                {item.companyName && item.siteName ?
                    <div className="d-flex align-items-center">
                        <span className="mb-0">{item.companyName}  {'('}{item.siteName}{')'}</span>
                    </div> :
                    <div className="d-flex align-items-center">
                        <span className="mb-0">{item.companyName}</span>
                    </div>
                }

            </>)

        },

        //{
        //    title: "Site Name",
        //    dataIndex: "siteName",
        //    className: "site-col"

        //},

        {
            title: t("ProjectName"),
            dataIndex: "projectName",

        },

        {
            title: t("IPAddress"),
            dataIndex: "ipAddress",
            //className: "ip-col",
            render: (ipAddress) => <Tooltip title={ipAddress}>{ipAddress}</Tooltip>,
           

        },

        {
            title: t("Message"),
            dataIndex: "message",
            className: "logs-address",
            render: (message) => <Tooltip title={message}>{message}</Tooltip>,
        },

        {
            title: t("ExecuteTime"),
            dataIndex: "executeTime",
            render: (executeTime) => getFullDate(executeTime),
           
        },
    ]

    return (
        <div>         
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                    <Layout>
                        <section className="User_section common ">
                            <Toaster />
                            <div className="container logs-container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="m-0">{t("ActivityLogs")}</h4>
                                    <div className="dropdown flex1">
                                        <button
                                            className={[
                                                "filter-btn dropdown-toggle btn d-flex align-items-center",
                                                dropdownChange === true ? "hide" : "",
                                            ].join(" ")}
                                            type="button"
                                            id="dropdownFilterMenuButton1"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="false"
                                            aria-expanded="false"
                                        >
                                            <span>
                                                <img src={'https://tekalogbeta.azurewebsites.net/static/media/search-ico-lite.c9c235ab7706d6c030741d0f56f56cc4.svg'} ></img> {t("Filter")}
                                            </span>
                                        </button>
                                        <ul
                                            className={[
                                                "dropdown-menu entries-dropdown-search",
                                                dropdownChange === true ? "hide" : "",
                                            ].join(" ")}
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <div className="search--tracking-col">
                                                        <label class="form-label">{t("UserName")}</label>
                                                        <input
                                                            className="form-control"
                                                            placeholder={t("EnterUserName")}
                                                            type="text"
                                                            value={username}
                                                            onChange={(e) => {
                                                                const inputText = e.target.value.trimLeft();
                                                                const cleanedText = inputText.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces

                                                                setUserName(cleanedText);
                                                                //setUserName(e.target.value.replace(/\s{2}/g, ''));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="search--asn-col">
                                                        <label class="form-label">{t("Company")}</label>
                                                        <input
                                                            className="form-control"
                                                            placeholder={t("EnterCompanyName")}
                                                            type="text"
                                                            value={companyname}
                                                            onChange={(e) => {
                                                                const inputText = e.target.value.trimLeft();
                                                                const cleanedText = inputText.replace(/[^a-zA-Z\s]/g, '');
                                                                setCompanyName(cleanedText);
                                                                //setCompanyName(e.target.value.replace(/\s{2}/g, ''));
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <div className="search--asn-col">
                                                        <label class="form-label">{t("Site")}</label>
                                                        <input
                                                            className="form-control"
                                                            placeholder={t("EnterSiteName")}
                                                            type="text"
                                                            value={sitename}
                                                            onChange={(e) => {
                                                                const inputText = e.target.value.trimLeft();
                                                                const cleanedText = inputText.replace(/[^a-zA-Z0-9\s]/g, '');
                                                                setSiteName(cleanedText);
                                                                // setSiteName(e.target.value.replace(/\s{2}/g, ''));
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <div className="form-group">
                                                        <label> {t("Projects")} </label>
                                                        <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                            value={appid}
                                                            key={appid}

                                                            onChange={(e) => {
                                                                // setAppId(e.target.value)
                                                                if (e.target.value == "Select Project") {
                                                                    setAppId(null)

                                                                }
                                                                else {
                                                                    setAppId(e.target.value)
                                                                }

                                                            }}
                                                        >
                                                            <option selected="">{t("SelectProject")}</option>
                                                            {
                                                                allAppData && allAppData.map(app =>
                                                                    app.appId != 7 ?
                                                                        <option key={app.appId} value={app.appId}>
                                                                            {app.name} </option> : null
                                                                )
                                                            }
                                                        </select>

                                                    </div>
                                                </div>


                                                <div className="col-md-12 d-flex justify-content-end">
                                                    {username != "" || companyname != "" || sitename != "" || appid != null ?
                                                        <button
                                                            className="btn btn-primary ms-auto "
                                                            onClick={SearchControl}
                                                        >
                                                            {t("Search")}
                                                        </button> :

                                                        <button
                                                            className="btn btn-primary ms-auto "
                                                            onClick={SearchControl}
                                                            disabled
                                                        >
                                                            {t("Search")}
                                                        </button>
                                                    }
                                                    <button
                                                        className="btn btn-bordered pe-3 ps-3 ms-2"
                                                        onClick={handleCancel}
                                                    >
                                                        {t("Cancel")}
                                                    </button>
                                                    <button
                                                        className="btn btn-bordered pe-3 ps-3 ms-2"
                                                        onClick={handleSearchClear}
                                                    >
                                                        {t("Reset")}
                                                    </button>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary refresh-button ml-3" type="button" title={t("ClicktoRefresh")}> <img src={Refresh} onClick={refreshLogs}></img></button>
                                        {/* <button className="btn btn-primary refresh-btn ml-2" onClick={refreshLogs}>Refresh</button>*/}
                                    </div>

                                </div>
                                <div className="Viewer-table index--table position-relative common-table mb-4">

                                    <Table
                                        className='antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={showPagination ? {
                                            total: totalCount,
                                            pageSize: pageSize,
                                            current: currentPage,
                                            showSizeChanger: true,
                                            onChange: handlePageChange,
                                            pageSizeOptions: ["50", "100"],
                                        } : false}

                                    />
                                </div>
                            </div>
                        </section>
                    </Layout>
                </>
            }
          
        </div>
    )

}
export default connect()(UserLogs);

