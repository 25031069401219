import React from "react";
import 'smart-webcomponents-react/source/styles/smart.default.css';
import { Tree, TreeItem, TreeItemsGroup } from 'smart-webcomponents-react/tree';
import { connect } from 'react-redux';
import AccessibilityIcon from '@mui/icons-material/Engineering';
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const SupportTree = ({ data }) => {
    const { t } = useTranslation('supportTree');

    // Check if data is provided and is an array
    if (!data || data.length === 0 || data[0] == null) {
        return "";
    }   

    // Group data by groupName and aggregate TechCount for each group
    const groupedData = data.reduce((acc, { groupName, siteName, techCount }) => {

        if (!acc[groupName]) {
            acc[groupName] = { techCount: 0, sites: [] };
        }
        // Sum up TechCount for the group and add site information
        acc[groupName].techCount = techCount;
        acc[groupName].sites.push(siteName);
        return acc;
    }, {});

    return (
        <Tree id="supportTree" className="animation" autoLoadState showLines
            showRootLines autoSaveState filterMode="containsIgnoreCase" toggle-element-position="far">
            {data.length > 0 ?
                <TreeItem style={{
                    justifyContent: 'center',
                    background: '#B97A57',
                    color: '#fff',
                    cursor: 'pointer'
                }}
                >
                    {t("SupportGroupsSites")}
                </TreeItem> : ""
            }
            {Object.entries(groupedData).map(([groupName, groupData]) => (
                <TreeItemsGroup key={groupName}>
                    <span className="smart-tree-tech-container">
                        <span className="smart-tree-tech-group-name">{groupName}</span>
                        <AccessibilityIcon className="smart-tree-tech-icon-style" />

                        <Tooltip title={`${t("SupportTechnicianCount ")} (${groupData.techCount})`}>
                            <span className="smart-tree-tech-count">
                                {groupData.techCount}
                            </span>
                        </Tooltip>
                    </span>

                    {groupData.sites.map((siteName) => (
                        <TreeItem key={siteName}>{siteName}</TreeItem>
                    ))}
                </TreeItemsGroup>
            ))}
        </Tree>
    );
}

export default connect()(SupportTree);
