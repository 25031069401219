import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import aggregated locale files
import en from "../locales/en";
import de from "../locales/de";
import it from "../locales/it";

// Initialize i18n
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en,
            de,
            it,
        },
        lng: "en", // Default language
        fallbackLng: "en", // Fallback language
        interpolation: { escapeValue: false }, // React already safeguards against XSS
    });

export default i18n;
