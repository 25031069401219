import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import { utils, writeFile } from 'xlsx';
import XLSIcon from "../../assets/images/dropdownFile.svg";
import { Select, Input } from 'antd';
import Loader from "../loader";
import Table from "antd/lib/table";
import { Tooltip, Collapse, Divider, DatePicker, Space } from 'antd';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Edit_light from "../../assets/images/Edit_light.svg";
import Layout from '../Layout';
import { getallsupportdata, updateprioritydata, updateassigneddetails, updatestatusdetails } from '../../Redux/Actions/SupportActions';
import { getworkitemsupportdata } from '../../Redux/Actions/WorkItemAction';
import {
    fetchAllSupportdata, fetchCallOrderdata, fetchWorkItemswithsupport, fetchUserRoledata, fetchUsersFromNotification,
    fetchUsersByGroupId
} from "../../Redux/Api/fetch.api";
import LogIcon from '../../assets/images/log.png'
import searchIcon from "../../assets/images/search_icon.svg";
import ReloadIcon from "../../assets/images/reset_icon.svg";
import ApplyIcon from "../../assets/images/apply-icon.svg";
import AcknowledgeIcon from "../../assets/images/acknowledge.svg";
import UpdateIcon from "../../assets/images/update.svg";
import PhoneIcon from "../../assets/images/phone-icon.svg";
import MessageIcon from "../../assets/images/message-icon.svg";
import PlayIcon from "../../assets/images/play_circle.svg";
import PdfIcon from "../../assets/images/pdf_icon.svg";
import MicIcon from '@mui/icons-material/Mic';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcCallSharpIcon from '@mui/icons-material/AddIcCallSharp';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import * as url from "../../api/uri_helper";
import axios from "axios";
/*import { Modal } from 'antd';*/
import AttachmentIcon from '@mui/icons-material/Attachment';
import CollectionsIcon from '@mui/icons-material/Collections';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Modal, Carousel}  from "react-bootstrap";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getuserroleandmenu, getuserslistfromnotification } from '../../Redux/Actions/UsersActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { Howl } from 'howler';
import dayjs from 'dayjs';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/GroupAddRounded';
import { useTranslation } from "react-i18next";

const SupportIndex = () => {
    const { t } = useTranslation('support');
    const { REACT_APP_SUPPORT_PAGESIZE } = process.env;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [assignedtable, setAssignedTable] = useState([]);
    const [acknowledgedtable, setAcknowledgedTable] = useState([]);
    const [closedtable, setClosedTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_SUPPORT_PAGESIZE);
    const [actionlist, setActionList] = useState([]);
    const history = useHistory();
   const [newSupportCount, setNewSupportCount] = useState(0);
    const [showCarouselModal, setShowCarouselModal] = useState(false);
    const [ispriority, setIsPriority] = useState(false);
   const [attachmentType, setAttachmentType] = useState();
   const [currentIndex, setCurrentIndex] = useState(0);
    const [roleName, setRoleName] = useState(null);
    const [allUserData, setAllUserData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [editableRow, setEditableRow] = useState(null);
    const [rangeEnd, setRangeEnd] = useState('');
    const [resetDisplay, setResetDisplay] = useState(false);
    const [rangeStart, setRangeStart] = useState('');
   const handleSelect = (selectedIndex, e) => {
    setCurrentIndex(selectedIndex);
    const mediaElements = document.querySelectorAll('video, audio');
    mediaElements.forEach(element => {
        element.pause();
    });
  };
     
  const Attachment = {
      IMAGE: 1,
      VIDEO: 2,
      DOCUMENT: 3,
      VOICE: 4
    };
   
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['5' ,'10', '20'],
            showSizeChanger: true,
        },
    });
    const [assignedoptions, setAssignedOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['5', '10', '20'],
            showSizeChanger: true,
        },
    });

    const [acknowledgedoptions, setAcknowledgedOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['5', '10', '20'],
            showSizeChanger: true,
        },
    });

    const [closedoptions, setClosedOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['5', '10', '20'],
            showSizeChanger: true,
        },
    });
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()   
    const [priority, setPriority] = useState(null)
    const [status, setStatus] = useState("")
    const [actiontaken, setActionTaken] = useState("")         
    const [supportId, setSupportId] = useState();
    const [applyId, setApplyId] = useState();
    const [sitename, setSiteName] = useState("");
    const [workitemid, setWorkItemId] = useState();
    const [priorityindex, setPriorityIndex] = useState();
    const [callOrderIndex, setCallOrderIndex] = useState();
    const [statusindex, setStatusIndex] = useState();
    const [acknowledgeId, setAcknowledgeId] = useState();
    const loggedUserId = localStorage.getItem('UserId')    
    const [isNewRequestOpen, setisNewRequestOpen] = useState(true);
    const [isAssignedOpen, setisAssignedOpen] = useState(true);
    const [isOnProcessedOpen, setisOnProcessedOpen] = useState(true);    
    const [updateModal, setUpdateModal] = useState(false);
    const [applyModal, setApplyModal] = useState(false);
    const [acknowledgeModal, setAcknowledgeModal] = useState(false);
    const [supportAttachmentModal, setSupportAttachmentModal] = useState(false);
    const [AttachmentSrc, setAttachmentSrc] = useState();
    const [userId, setUserId] = useState();
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [callOrderData, setCallOrderData] = useState([]);
    const [supportData, setSupportData] = useState([]);
    const { TextArea } = Input; 
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const userRoledata = allUserTypes.data && allUserTypes.data.userRoleDetails;
    const userRole = userRoledata && userRoledata[0].roleName;
    const [technicianOptions, setTechnicianOptions] = useState({});
   


    useEffect(() => {
        getSupportDetails();       
        getRoleDetails();
        getworkitemsupportdetails();
        getallusersdata();
        //getallusersbygroupid();
    }, []);

    useEffect(() => {
        getSupportDetails();
    }, [resetDisplay]);

    const exportAsExcel = () => {
        const rangeStartDate = rangeStart ? moment(rangeStart.$d).format('MM/DD/YYYY hh:mm:ss A') : null;
        const rangeEndDate = rangeEnd ? moment(rangeEnd.$d).format('MM/DD/YYYY hh:mm:ss A') : null;
        const payload = {
            userId: loggedUserId,
            startDate: rangeStartDate,
            endDate: rangeEndDate
        }
        fetchAllSupportdata(payload)  
            .then(response => {
                dispatch(getallsupportdata(response.data));

                const assignedData = response.data.data.assignedData || [];
                const acknowledgeData = response.data.data.acknowledgeData || [];
                const closedData = response.data.data.closedData || [];

                const todayDate = new Date();
                todayDate.setHours(todayDate.getHours() - 5);
                const formattedDate = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
                const formattedDateTime = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()} ${todayDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
               
                let assignedColumns = [
                    "Sr. No.",
                    "Support Id",
                    "Company",
                    "Site",
                    "App",
                    "Description",
                    "Requested By",
                    "Request Date",
                    "Phone number",
                    "Priority",
                    "Assigned To",
                    "Assigned Date"
                ];

                let acknowledgeColumns = [
                    ...assignedColumns,
                    "Start Date",
                    "Status",
                    "Note",
                ];

                let closedColumns = [
                    ...assignedColumns,
                    "Start Date",
                    "Status",
                    "Closed Date"
                ];

                let combinedData = [
                    [],
                    [`Project: TEKaVerse`],
                    [`Extracted Date:`, ` ${formattedDateTime}`, "", "", "", "", "", "", "", "Powered By : AuthenTEK"],
                    [`Date Range :  ${rangeStartDate != null && rangeEndDate != null ? rangeStartDate + " To " + rangeEndDate : rangeStartDate != null ? rangeStartDate : ""}`],

                    [], []
                ];

                const generateRows = (data, columns) => {
                    return data.map((item, index) => {
                        const rowData = columns.map(column => {
                            switch (column) {
                                case "Sr. No.":
                                    return index + 1;
                                case "Support Id":
                                    return item.supportId || "";
                                case "Company":
                                    return item.companyName || "";
                                case "Site":
                                    return item.siteName || "";
                                case "App":
                                    return item.appName || "";
                                case "Description":
                                    return item.description || "";
                                case "Requested By":
                                    return item.requestedBy || item.desktopName || "";
                                case "Request Date":
                                    return item.requestDate || "";
                                case "Phone number":
                                    return item.phoneNumber === "null" ? "" : item.phoneNumber || "";
                                case "Priority":
                                    return item.priority === 0 ? "High" : item.priority === 1 ? "Medium" : "Low";
                                case "Assigned To":
                                    return item.assignedName || "";
                                case "Assigned Date":
                                    return item.assignedDate || "";
                                case "Start Date":
                                    return item.startDate || "";
                                case "Note":
                                    return item.actionTaken || "";
                                case "Status":
                                    return item.status === 0 ? 'Active' : item.status === 1 ? 'Pending' : item.status === 2 ? 'Working' : item.status === 3 ? 'Closed' : "";
                                case "Closed Date":
                                    return item.closedDate || "";
                                default:
                                    return "";
                            }
                        });
                        return rowData;
                    });
                };

                const addSection = (title, columns, data) => {
                    combinedData.push(
                        [], // Adding an empty row for spacing
                        [title],
                        columns,
                        ...(data.length > 0 ? generateRows(data, columns) : new Array(1).fill(columns.map(() => ""))) // Add empty rows if no data
                    );
                };

                addSection("Assigned Support Requests", assignedColumns, assignedData);
                addSection("On Process Support Requests", acknowledgeColumns, acknowledgeData);
                addSection("Closed Support Requests", closedColumns, closedData);

                const currentDate = new Date();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');
                const year = currentDate.getFullYear();
                const hours = String(currentDate.getHours()).padStart(2, '0');
                const minutes = String(currentDate.getMinutes()).padStart(2, '0');

                let wb = utils.book_new();
                let ws = utils.json_to_sheet(combinedData, { blankrows: 3 });

                ws['!cols'] = [];
                const defaultColWidth = { wpx: 80 };
                assignedColumns.forEach((col, index) => {
                    if (index < 1) {
                        ws['!cols'].push(defaultColWidth);
                    } else if (index === 1 || index === 7) {
                        ws['!cols'].push({ wpx: 120 });
                    } else if (index === 11 || index === 12 || index === 14) {
                        ws['!cols'].push({ wpx: 160 });
                    } else {
                        ws['!cols'].push({ wpx: 80 });
                    }
                });

                utils.book_append_sheet(wb, ws, `Support Request List`);
                const downloadFileName = `Support_Requests_${year}${month}${day}_${hours}${minutes}.xlsx`;

                writeFile(wb, downloadFileName);
            })
            .catch((err) => {
                console.log("Error exporting data to Excel:", err);
            });
    };

   
    
    const getSupportDetails = () => {    
        setLoading(true)
        const rangeStartDate = rangeStart ? moment(rangeStart.$d).format('MM/DD/YYYY hh:mm:ss A') : null;
        const rangeEndDate = rangeEnd ? moment(rangeEnd.$d).format('MM/DD/YYYY hh:mm:ss A') : null;
        const payload = {
            userId: loggedUserId,
            startDate: rangeStartDate,
            endDate: rangeEndDate
        }
        fetchAllSupportdata(payload)                         
            .then(response => {
              
                dispatch(getallsupportdata(response.data))                         
                setAssignedTable(response.data.data.assignedData)            
                setNewSupportCount(response.data.data.assignedData.length)
                setAcknowledgedTable(response.data.data.acknowledgeData)
                setClosedTable(response.data.data.closedData)
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.data.newRequestCount,
                    },
                });
                setAssignedOptions({
                    ...assignedoptions,
                    pagination: {
                        ...assignedoptions.pagination,
                        total: response.data.data.assignedCount,
                    },
                });

                setAcknowledgedOptions({
                    ...acknowledgedoptions,
                    pagination: {
                        ...acknowledgedoptions.pagination,
                        total: response.data.data.acknowledgedCount,
                    },
                });

                setClosedOptions({
                    ...closedoptions,
                    pagination: {
                        ...closedoptions.pagination,
                        total: response.data.data.closedCount,
                    },
                });
               
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const onDownloadButtonClick = (data) => {
        const url = data;
        debugger
        const filename = data.substring(data.lastIndexOf('/') + 1);
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                saveAs(blob, filename);
            })
            .catch((error) => {
                console.log("Download error:", error);
            });
    };

    const handleSearchRequest = () => {
        if (rangeStart && !rangeEnd) {
            toast.error(t("PleaseentertheEndDate"), {
                autoClose: 8000,
            });
            return;
        }
        if (!rangeStart && rangeEnd) {
            toast.error(t("PleaseenterthestartDate"), {
                autoClose: 8000,
            });
            return;
        }
        if (rangeEnd < rangeStart) {
            toast.error(t("EnddateshouldnotbelessthanStartdate"), {
                autoClose: 8000,
            });
            return;
        };
        setResetDisplay(true);
        getSupportDetails()
    }

    const handleResetSearch = () => {
        
        setRangeStart(null)
        setRangeEnd(null)
        setResetDisplay(false);
    }
   

    const getRoleDetails = () => {
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))               
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getworkitemsupportdetails = () => {
        setLoading(true)
        fetchWorkItemswithsupport()
            .then(response => {
                dispatch(getworkitemsupportdata(response.data))
                setSupportData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }
       
    const getallusersdata = () => {
        setLoading(true)
        fetchUsersFromNotification()
            .then(response => {
                dispatch(getuserslistfromnotification(response.data))
                setAllUserData(response.data.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
    const supportUser = allUserData && allUserData.includes(parseInt(loggedUserId));

    const getallusersbygroupid = (sitename) => { 
        
        setLoading(true)
        fetchUsersByGroupId(sitename)
            .then(response => {       
                
                setCallOrderData(response.data.data)
                setLoading(false)
                
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

   

    const handleAssignedChange = (pagination, sorter, current) => {
        const updatedOptions = { ...assignedoptions, pagination };
        setAssignedOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const handleAcknowledgedChange = (pagination, sorter, current) => {
        const updatedOptions = { ...acknowledgedoptions, pagination };
        setAcknowledgedOptions(updatedOptions);
        setPageData(updatedOptions)
        
    };

    const handleClosedChange = (pagination, sorter, current) => {
        const updatedOptions = { ...closedoptions, pagination };
        setClosedOptions(updatedOptions);
        setPageData(updatedOptions)

    };

    const playAudio = (source) => {
        debugger;
      
        const sound = new Howl({
            src: [source]
        });
        sound.play();
    };

    const handleSupportChange = (id) => {
        var win = window.open("/Workitem/Create/Support/" + id, '_blank');
        win.focus();
    }

    const opensupportreport = () => {
        var win = window.open("/SupportReport", '_blank');
        win.focus();
    }

    const openloadreport = () => {
        getSupportDetails();
    }

    const openWorkitemEdit = (id) => {       
        var win = window.open("/Workitem/Edit/" + id, '_blank');
        win.focus();
    }

    const handleImageClick = (supportAttachments) => {
        console.log(supportAttachments)
        setAttachmentSrc(supportAttachments);
        setSupportAttachmentModal(true);
      };

      const handleCarouselImageClick = (attachmentTypeId) => {
        setAttachmentType(attachmentTypeId);
        setShowCarouselModal(true);
      };

      const handleCarouselModalClose = () => {
        setCurrentIndex(0);
        setShowCarouselModal(false);
      };
    
      const handleModalClose = () => {
        setSupportAttachmentModal(false);
      };
   
    const Prioritydata = [
        {
            key: '0',
            id: 0,
            name: 'High',           
        },
        {
            key: '1',
            id: 1,
            name: 'Medium',           
        },

        {
            key: '2',
            id: 2,
            name: 'Low',
        },

    ];

    const Statusdata = [
        {
            key: '0',
            id: 0,
            name: 'Active',


        },
        {
            key: '1',
            id: 1,
            name: 'Pending',


        },

        {
            key: '2',
            id: 2,
            name: 'Working',
        },

        {
            key: '3',
            id: 3,
            name: 'Closed',
        },

    ];

    const UpdatePriority = async (id) => {          
        setLoading(true)   
        const selectedPriorityIndex = priorityindex;
        const selectedPriorityValue = priorities[selectedPriorityIndex];
        const selectedCallOrderIndex = callOrderIndex;
        const selectedCallOrderValue = callOrder[callOrderIndex];

        const formData = new FormData();
        formData.append("Priority", selectedPriorityValue != undefined ? selectedPriorityValue : "");
        formData.append("CallOrderUserId", userId != undefined ? userId : "")  
        formData.append("SupportId", id);  
        const response_ = await axios({
            method: 'post',
            url: url.UPDATE_PRIORITY,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {

                toast.error(t("Invaliduser"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updateprioritydata(response.data))
                toast.success(t("SupportDataAdded"), {
                    className: "toastSuccess",
                });             
                setApplyModal(false)               
                setNewSupportCount(prevCount => prevCount - 1)
                setEditable(false)
                setIsPriority(false)
                getSupportDetails();
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)

    }

    const UpdateAssignedData = async (id) => {        
        setLoading(true)
        const formData = new FormData();        
        formData.append("SupportId", id);
        const response_ = await axios({
            method: 'post',
            url: url.UPDATE_ASSIGNEDDATA,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {

                toast.error(t("Invaliduser"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updateassigneddetails(response.data))
                toast.success(t("AcknowledgeUpdated"), {
                    className: "toastSuccess",
                });
                getSupportDetails();
                setAcknowledgeModal(false)

            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)

    }

    const UpdateStatusData = async (id) => {           
        setLoading(true)
        const selectedStatusIndex = statusindex;
        const selectedStatusValue = statusnew[selectedStatusIndex];
        const formData = new FormData();
        formData.append("SupportId", id);
        formData.append("Status", selectedStatusValue == undefined ? null : selectedStatusValue);
        formData.append("ActionTaken", actiontaken);
        const response_ = await axios({
            method: 'post',
            url: url.UPDATE_STATUSDATA,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("Invaliduser"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updatestatusdetails(response.data))
                toast.success(t("StatusUpdated"), {
                    className: "toastSuccess",
                });
               
                setUpdateModal(false)
                setStatusNew({})
                getSupportDetails();
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)

    }

    const [priorities, setPriorities] = useState({});
    const handlePriorityChange = (value, index) => {
       // setIsPriority(true)
        setPriorities(prevState => ({
            ...prevState,
            [index]: value 
        }));

        setIsPriority(prevState => ({
            ...prevState,
            [index]: true
        }));
    };

    const [callOrder, setCallOrder] = useState({});
    const handleCallOrderChange = (value, index) => {
        
        setCallOrder(prevState => ({
            ...prevState,
            [index]: value 
        }));
        setFilteredOptions([])
    };

    const [statusnew, setStatusNew] = useState({});
    const handleStatusChange = (value, index) => {
        setStatusNew(prevState => ({
            ...prevState,
            [index]: value
        }));
    };

    const handleInputForDropdown = (input) => {
        debugger
        const filtered = callOrderData && callOrderData.filter(item => {
            const fullName = item.userName;
            return fullName.toLowerCase().includes(input.toLowerCase());
        });
        setFilteredOptions(filtered);
    };

    const handleEdit = (Id, sitename) => {
        setEditable(true)
        setEditableRow(Id);
        getallusersbygroupid(sitename)
    }

    const handleSupportLog = (supportId) => {
        history.push(`/LogDetails/${supportId}`);
        // console.log("hello")
    }


    const assignedcolumns = [
        {
            title: t("RequestID"),
            dataIndex: "supportId",
            render: (supportId) => `#${supportId}`
        },

        {
            title: t("Company"),
            dataIndex: "companyName",
        },

        {
            title: t("Site"),
            dataIndex: "siteName",
        },

        {
            title: t("App"),
            dataIndex: "appName",
        },

        {
            title: t("RequestType"),
            dataIndex: "isCallRequest",
            render: (value, item, index) =>
            (<>
                {item.isCallRequest == 1 ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("Requestforcall")}>  <img src={PhoneIcon} className="apply-img"
                        ></img></Tooltip>
                    </div> :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("RequestforMessage")}> <img src={MessageIcon} className="apply-img"
                        ></img></Tooltip>
                    </div>

                }
            </>)
        },

        {
            title: t("Description"),
            dataIndex: "description",
            className: "description_support",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },

        {
            title: t("Preview"),
            render: (record) => {
                return (
                    record.supportAttachments.length > 0 && (
                        <Tooltip title={t("Preview")}>
                            <AttachmentIcon onClick={() => handleImageClick(record.supportAttachments)} style={{ color: '#fbaf5d', height: '30px', width: '30px', cursor: 'pointer' }} fontSize="medium" />
                        </Tooltip>
                    )
                );
            }
        },

        {
            title: t("RequestedBy"),
            dataIndex: "requestedBy",
            className: "name-wrap"
        },
        
        {
            title: t("RequestDate"),
            dataIndex: "requestDate",
            className: "date-wrap",
           // render: (requestDate) => getFullDate(requestDate),
        },

        {
            title: t("PhoneNumber"),
            dataIndex: "phoneNumber",
            className: "date-wrap",
            render: (phoneNumber) =>
            (<>
                {phoneNumber !== null && phoneNumber !== "null" && phoneNumber !== undefined && (
                        <div style={{ display: "flex", height: "100%" }}>{phoneNumber}
                            <Tooltip title={t("CallNow")}>
                                <a class="" href={"tel:+" + phoneNumber}><AddIcCallSharpIcon style={{ color: 'coral', marginLeft: '5px', cursor: "pointer" }} fontSize="small" ></AddIcCallSharpIcon></a>
                            </Tooltip>
                        </div>
                )}
            </>)
        },

        ...(userRole && userRole == "SuperAdmin" ?
        [
        {
            title: t("Priority"),
            // key: 'i',           
            render: (record, row, i) => (
                <div key={record.supportId} className='d-flex'>

                    <Select
                        aria-label=".form-select-lg example"
                        style={{ width: 110 }}
                        placeholder={t("Priority")}
                        id={i + 1}
                        key={row.supportId}
                        value={priorities[i]}
                         defaultValue={record.priority}                                                                       
                        onChange={(value) => handlePriorityChange(value, i)}
                    >
                        {
                            Prioritydata && Prioritydata.map((data, k) => (
                                <Option key={data.id} value={data.id}>
                                    {data.name}
                                </Option>)
                            )}
                    </Select>
                </div>
            )
            },
            ] :
            [
                {
                    title: t("Priority"),
                    dataIndex: "priority",
                    render: (value, item, index) =>
                    (<>
                        {item.priority == 0 ?
                            <div>
                                <span>High</span>
                            </div> :
                            item.priority == 1 ?
                                <div>
                                    <span>Medium</span>
                                </div> :
                                <div>
                                    <span>Low</span>
                                </div>
                        }
                    </>)
                },
            ]
        ),

        {
            title: t("Technician"),
            dataIndex: "",
            render: (text, record, index) =>
            (<>
                {editable && editableRow === record.supportId ?
                    <div key={record.supportId} className='d-flex'>
                        <Select className=""
                            aria-label=".form-select-lg example"
                            placeholder="Select User"
                            showSearch                           
                            defaultValue={record.assignedName}                          
                            onChange={(value) => {
                                setUserId(value)
                            }}
                            onSearch={(value) => {
                                handleInputForDropdown(value)
                            }}
                            filterOption={false}
                            style={{ width: 150 }}
                        >
                            {filteredOptions.length > 0 ?
                                filteredOptions && filteredOptions.map(item =>
                                    <Option key={item.userId} value={item.userId}>
                                        {item.userName}
                                    </Option>
                                )
                                :
                                callOrderData && callOrderData.map(item =>
                                    <Option key={item.userId} value={item.userId}>
                                        {item.userName}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>
                    :
                    <label>{record.assignedName}</label>
                }
            </>
            )

        },

      
       
        {
            title: t("AssignedDate"),
            dataIndex: "assignedDate", 
            className: "date-wrap",   
            render: (text, record, index) =>
            (<>
                {record.assignedDate ? record.assignedDate :
                    <div>
                        <span className="noAssignedName">{t("Pleaseassignagroup")}</span>
                        {userRole && userRole === "SuperAdmin" ?
                            <Tooltip title={t("Clicktoopensupportgroup")}>
                                <a
                                    className="btn btn-primary supportgrp-btn m-2"
                                    href={`/CallOrder?group=${encodeURIComponent(record.groupName)}&supportId=${encodeURIComponent(record.supportId)}&siteId=${encodeURIComponent(record.siteId)}`}
                                    target="_blank"
                                >
                                    <GroupIcon style={{ fontSize: '18px' }} />
                                </a>

                            </Tooltip> : ""
                        }
                    </div>
                   
                }
            </>
            )
        },

        
                {
                    title: t("Assign"),
                    key: 'index',
                  

                    render: (text, record, index) => (<>
                        {(userRole && userRole === "SuperAdmin" || record.allowUpdate === true) && (
                            record.assignedName !== null && (
                        (editable && editableRow === record.supportId) || ispriority[index] ?

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <Tooltip title={t("Clicktoassign")}>
                                <button
                                    className="assign-btn btn btn-primary px-2 py-1"
                                    id={index + 1}
                                >
                                    <img src={ApplyIcon} onClick={() => handleApply(record.supportId, index)} className="apply-img"
                                    ></img>
                                    </button>
                                </Tooltip>
                            </div> :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <Tooltip title={t("ClicktoChangeTechnician")}>
                                <button
                                    className="assign-btn btn btn-primary px-2 py-1"
                                    id={index + 1}
                                >
                                    <img src={Edit_light} onClick={() => handleEdit(record.supportId, record.siteName)} className="edit-img"
                                    ></img>
                                    </button>
                                </Tooltip>
                            </div> 
                           )
                        )}

                    </>)
                },
          
       

      
        {
            title: t("DoAcknowledge"),
            key: 'index',
                     
            render: (text, record, index) => (<>
                {record.assignedName !== null && userRole && userRole == "SuperAdmin" || record.allowMaintenance == true?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("Clicktoacknowledge")}>
                        <button className="acknowledge-btn btn btn-primary px-2 py-1" id={index + 1}>
                            <img src={AcknowledgeIcon} onClick={(e) => handleAcknowledge(record.supportId)} className="apply-img"
                            ></img>
                            </button>
                        </Tooltip>
                    </div> : ""
                }
            </>)
        },
           
      

        {
            title: t("Log"),
            render: (record, index) => (<>
                {record.supportId !== null && record.assignedDate != null ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("ClicktoviewLog")}>
                            <button className="acknowledge-btn btn btn-primary px-2 py-1"  onClick={(e) => handleSupportLog(record.supportId)}>
                                <img style={{ filter: "invert(0) brightness(2)" }} src={LogIcon} alt="LogDetails" /></button>
                        </Tooltip>
                    </div>

                ) : ''}
            </>)
        }

            

    ]
    const handleActionChange = (value, index) => {               
        const newData = [...acknowledgedtable];       
        newData[index].actionTaken = value;       
        setAcknowledgedTable(newData);
        setActionTaken(newData[index].actionTaken)       
    };

    const handleApply = (supportId, index) => {
        
        const selectedPriority = priorities[index];
       // const selectedCallOrder = callOrder[index];
        if (selectedPriority == undefined && userId == null && userId == undefined) {
            toast.error(t("PleaseUpdatePriorityorTechnician"), {
                className: "toastSuccess",
            });
            return;
        }      
        setApplyId(supportId)
        setPriorityIndex(index)
       // setCallOrderIndex(index)
        setApplyModal(true);
    };

    const handleUpdate = (supportId, index, record) => {       
        const selectedStatus = statusnew[index];
        if (selectedStatus == undefined) {
            toast.error(t("PleaseUpdateStatus"), {
                className: "toastSuccess",
            });           
        }
        else {
            setSupportId(supportId)
            setStatusIndex(index)
           setUpdateModal(true);
        }
    };

    const handleAcknowledge = (id) => {
        setAcknowledgeId(id)
        setAcknowledgeModal(true);
    };

    const handleUpdateModalClose = () => {
        setUpdateModal(false);
    };

    const handleApplyModalClose = () => {
        setApplyModal(false);
    };

    const handleAcknowledgeModalClose = () => {
        setAcknowledgeModal(false);
    };

    const isImage = (attachments) => {
        if (!Array.isArray(attachments) || attachments.length === 0) return false; // Return false if attachments is not an array or empty
        const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
        for (let attachment of attachments) {
            if (!attachment.source) continue; // Skip if source is null or undefined
            const extension = attachment.source.substring(attachment.source.lastIndexOf("."));
            if (imageExtensions.includes(extension.toLowerCase())) {
                return true; // Return true if any attachment is an image
            }
        }
        return false; // Return false if none of the attachments are images
    };

    const isDocument = (attachments) => {
        if (!Array.isArray(attachments) || attachments.length === 0) return false; // Return false if attachments is not an array or empty
        const documentExtensions = [".pdf"];
        for (let attachment of attachments) {
            if (!attachment.source) continue; // Skip if source is null or undefined
            const extension = attachment.source.substring(attachment.source.lastIndexOf("."));
            if (documentExtensions.includes(extension.toLowerCase())) {
                return true; // Return true if any attachment is a document
            }
        }
        return false; // Return false if none of the attachments are documents
    };

    const isAudio = (attachments) => {
        if (!Array.isArray(attachments) || attachments.length === 0) return false; // Return false if attachments is not an array or empty
        const audioExtensions = [".mp3", ".wav", ".ogg", ".webm"]; // Add more audio extensions as needed
        for (let attachment of attachments) {
            if (!attachment.source) continue; // Skip if source is null or undefined
            const extension = attachment.source.substring(attachment.source.lastIndexOf("."));
            if (audioExtensions.includes(extension.toLowerCase())) {
                return true; // Return true if any attachment is an audio file
            }
        }
        return false; // Return false if none of the attachments are audio files
    };

    const isVideo = (attachments) => {
        if (!Array.isArray(attachments) || attachments.length === 0) return false; // Return false if attachments is not an array or empty
        const videoExtensions = [".mp4", ".avi", ".mov", ".mkv", ".wmv"]; // Add more video extensions as needed
        for (let attachment of attachments) {
            if (!attachment.source) continue; // Skip if source is null or undefined
            const extension = attachment.source.substring(attachment.source.lastIndexOf("."));
            if (videoExtensions.includes(extension.toLowerCase())) {
                return true; // Return true if any attachment is a video file
            }
        }
        return false; // Return false if none of the attachments are video files
    };

       
    const acknowledgedcolumns = [    

        {
            title: t("RequestID"),
            dataIndex: "supportId",
            render: (supportId) => `#${supportId}`
        },
        {
            title: t("Company"),
            dataIndex: "companyName",
        },

        {
            title: t("Site"),
            dataIndex: "siteName",
        },

        {
            title: t("App"),
            dataIndex: "appName",
        },

        {
            title: t("RequestType"),
            dataIndex: "isCallRequest",
            render: (value, item, index) =>
            (<>
                {item.isCallRequest == 1 ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("Requestforcall")}> <img src={PhoneIcon} className="apply-img"
                        ></img></Tooltip>
                    </div> :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("RequestforMessage")}> <img src={MessageIcon} className="apply-img"
                        ></img></Tooltip>
                    </div>
                }
            </>)
        },

        {
            title: t("Description"),
            dataIndex: "description",
            className: "description_support",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },

        {
            title: t("Preview"),
            render: (record) => {
                return (
                    record.supportAttachments.length > 0 && (
                        <Tooltip title={t("Preview")}>
                            <AttachmentIcon onClick={() => handleImageClick(record.supportAttachments)} style={{ color: '#fbaf5d', height: '30px', width: '30px', cursor: 'pointer' }} fontSize="medium" />
                        </Tooltip>
                    )
                );
            }
        },

        {
            title: t("RequestedBy"),
            dataIndex: "requestedBy",
            className: "name-wrap"
        },
      
        {
            title: t("RequestDate"),
            dataIndex: "requestDate",
            className: "date-wrap",          

        },

        {
            title: t("PhoneNumber"),
            dataIndex: "phoneNumber",
            className: "date-wrap",
            render: (phoneNumber) =>
            (<>
                {
                    phoneNumber !== null && phoneNumber !== "null" && phoneNumber !== undefined && (
                        <div style={{ display: "flex", height: "100%" }}>{phoneNumber}
                            <Tooltip title="Call Now">
                                <a class="" href={"tel:+" + phoneNumber}><AddIcCallSharpIcon style={{ color: 'coral', marginLeft: '5px', cursor: "pointer" }} fontSize="small" ></AddIcCallSharpIcon></a>
                            </Tooltip>
                        </div>
                )}
            </>)
        },

        {
            title: t("Priority"),
            dataIndex: "priority",
            render: (value, item, index) =>
            (<>
                {item.priority == 0 ?
                    <div>
                        <span>High</span>
                    </div> :
                    item.priority == 1 ?
                        <div>
                            <span>Medium</span>
                        </div> :
                        <div>
                            <span>Low</span>
                        </div>
                }
            </>)
        },

        {
            title: t("AssignedTo"),
            dataIndex: "assignedName",
            className: "name-wrap"

        },

        {
            title: t("AssignedDate"),
            dataIndex: "assignedDate",
            className: "date-wrap",
            

        },

        {
            title: t("StartDate"),
            dataIndex: "startDate",
            className: "date-wrap",            
        },

        {
            title: t("Note"),
           // dataIndex: "actionTaken",
            key: 'i',
            render: (text, record, index) => (
                <TextArea
                    value={text.actionTaken}
                    onChange={(e) => handleActionChange(e.target.value, index)}
                    style={{ width: '250px' }}                    
                />
            ),
        },

        {
            title: t("SupportId"),
            key: 'i',
            render: (record, row, i) => {
                const foundSupportData = supportData && supportData.find(item => item.supportId === row.supportId);
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        {foundSupportData && foundSupportData.workTypeId === 3 ? `#${foundSupportData.workItemId}` : ""}
                    </div>

                )
            }
        },

        {
            title: t("SupportCase"),
            key: 'i',
            render: (record, row, i) => {
                const foundSupportData = supportData && supportData.find(item => item.supportId === row.supportId);
                return (
                    <div className='d-flex'>
                        {foundSupportData ? (
                            // Render text if supportId matches
                            <button className="activeSupportItem" onClick={() => openWorkitemEdit(foundSupportData.workItemId)}>
                                {foundSupportData.workStatus === 1 ? 'Active' :
                                    foundSupportData.workStatus === 2 ? 'Resolved' :
                                    foundSupportData.workStatus === 4 ? 'Testing' :
                                        foundSupportData.workStatus === 5 ? 'Closed' :
                                            //foundSupportData.workTypeId === 0 ? 'Report Bug Added' :
                                            //    foundSupportData.workTypeId === 1 ? 'Feature Request Added' :
                                                    foundSupportData.workTypeId === 3 ? t('SupportRequestAdded') :
                                                        ''}
                            </button>
                        ) : (
                           
                                <button className="support-btn btn btn-primary"
                                    id={i + 1}
                                    onClick={() => handleSupportChange(row.supportId)}
                                >
                                   {t("CreateSupportCase")}
                                </button>
                        )}
                    </div>

                )
            }
        },

       
        {
            title: t("Status"),
             key: 'i',           
            render: (record, row, i) => (
                <div className='d-flex'>
                    <Select 
                        aria-label=".form-select-lg example"
                        style={{ width: 100 }}
                        placeholder={t("Status")}
                        id={i + 1}
                       key={row.supportId}
                        defaultValue={record.status}
                       // value={statusnew[i]}
                       // onChange={(e) => setStatus(e)}     
                        onChange={(value) => handleStatusChange(value, i)}
                    >
                       
                        {
                            Statusdata && Statusdata.map((data, k) => (
                                <Option key={data.id} value={data.id}>
                                    {data.name}
                                </Option>)

                            )}

                    </Select>
                </div>

            )
        },

        {
            title: t("Update"),
            key: 'index',                      
            render: (text, record, index) => (<>
                {
                    <Tooltip title={t("Clicktoupdate")}>
                    <button className="acknowledge-btn btn btn-primary px-2 py-1"
                        id={index + 1}                                            
                    >
                        <img src={UpdateIcon}
                            onClick={() => handleUpdate(record.supportId, index, record)}
                            className="apply-img"                          
                        ></img>
                        </button>
                    </Tooltip>
                }
            </>)
        },

        {
            title: t("Log"),
            render: (record, index) => (<>
                {record.supportId !== null && record.assignedDate != null ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("ClicktoviewLog")}>
                            <button className="acknowledge-btn btn btn-primary px-2 py-1" onClick={(e) => handleSupportLog(record.supportId)}>
                                <img style={{ filter: "invert(0) brightness(2)" }} src={LogIcon} alt="LogDetails" /></button>
                        </Tooltip>
                    </div>

                ) : ''}
            </>)
        }
    ]

    const closedcolumns = [

        {
            title: t("RequestID"),
            dataIndex: "supportId",
            render: (supportId) => `#${supportId}`
        },
        {
            title: t("Company"),
            dataIndex: "companyName",
        },

        {
            title: t("Site"),
            dataIndex: "siteName",
        },

        {
            title: t("App"),
            dataIndex: "appName",
        },

        {
            title: t("RequestType"),
            dataIndex: "isCallRequest",
            render: (value, item, index) =>
            (<>
                {item.isCallRequest == 1 ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("Requestforcall")}> <img src={PhoneIcon} className="apply-img"
                        ></img></Tooltip>
                    </div> :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title={t("RequestforMessage")}> <img src={MessageIcon} className="apply-img"
                        ></img></Tooltip>
                    </div>
                }
            </>)
        },

        {
            title: t("Description"),
            dataIndex: "description",
            className: "description_support",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },

        {
            title: t("Preview"),
            render: (record) => {
                return (
                    record.supportAttachments.length > 0 && (
                        <Tooltip title={t("Preview")}>
                            <AttachmentIcon onClick={() => handleImageClick(record.supportAttachments)} style={{ color: '#fbaf5d', height: '30px', width: '30px', cursor: 'pointer' }} fontSize="medium" />
                        </Tooltip>
                    )
                );
            }
        },

        {
            title: t("RequestedBy"),
            dataIndex: "requestedBy",
            className: "name-wrap"
        },

        {
            title: t("RequestDate"),
            dataIndex: "requestDate",
            className: "date-wrap",

        },

        {
            title: t("PhoneNumber"),
            dataIndex: "phoneNumber",
            className: "date-wrap",
            render: (phoneNumber) =>
            (<>
                {
                    phoneNumber !== null && phoneNumber !== "null" && phoneNumber !== undefined && (
                        <div style={{ display: "flex", height: "100%" }}>{phoneNumber}
                            <Tooltip title={t("CallNow")}>
                                <a class="" href={"tel:+" + phoneNumber}><AddIcCallSharpIcon style={{ color: 'coral', marginLeft: '5px', cursor: "pointer" }} fontSize="small" ></AddIcCallSharpIcon></a>
                            </Tooltip>
                        </div>
                    )}
            </>)
        },

        {
            title: t("Priority"),
            dataIndex: "priority",
            render: (value, item, index) =>
            (<>
                {item.priority == 0 ?
                    <div>
                        <span>High</span>
                    </div> :
                    item.priority == 1 ?
                        <div>
                            <span>Medium</span>
                        </div> :
                        <div>
                            <span>Low</span>
                        </div>
                }
            </>)
        },

        {
            title: t("AssignedTo"),
            dataIndex: "assignedName",
            className: "name-wrap"

        },

        {
            title: t("AssignedDate"),
            dataIndex: "assignedDate",
            className: "date-wrap",


        },

        {
            title: t("StartDate"),
            dataIndex: "startDate",
            className: "date-wrap",
        },

        {
            title: t("Status"),
            dataIndex: "status",
            render: (value, item, index) =>
            (<>
                {item.status == 3 ?
                    <div>
                        <span>Closed</span>
                    </div> :
                   ""
                }
            </>)

        },

        {
            title: t("ClosedDate"),
            dataIndex: "closedDate",
            className: "date-wrap",

        },

        {
            title: t("Log"),
            render: (record, index) => (<>
                {record.supportId !== null && record.assignedDate != null ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Tooltip title="Click to view Log">
                            <button className="acknowledge-btn btn btn-primary px-2 py-1" onClick={(e) => handleSupportLog(record.supportId)}>
                                <img style={{ filter: "invert(0) brightness(2)" }} src={LogIcon} alt="LogDetails" /></button>
                        </Tooltip>
                    </div>

                ) : ''}
            </>)
        }
    ]

    const disableFutureDates = (current) => {
        const today = moment().startOf('day'); // Get today's date without time
        return current && current.isAfter(today, 'day'); // Disable dates after today
    };

    const onChange = (dateString) => {
        const combinedStartDate = dateString ? dayjs(dateString) : null;
        setRangeStart(combinedStartDate);
    };

    const onChangeEndRange = (dateString) => {
        const combinedEndDate = dateString ? dayjs(dateString) : null;
        setRangeEnd(combinedEndDate);
    };
    return (
        <div>
            {roleName == null || supportUser === null || supportUser === undefined ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" && !supportUser ? <NotAuthorised></NotAuthorised> :
                <>
                    <Layout supportCount={newSupportCount}>
                        <section className="Company_section common pt-3">
                            {loading ? <Loader /> : ''}
                            <Toaster />
                            <div className="report-header report-header d-flex justify-content-between align-items-center mb-2">
                                <div className="load-report">
                                  <div className="search-order-outer">                                              
                                                <Space direction="vertical">
                                                    <DatePicker
                                                        format="MM/DD/YYYY HH:mm:ss"
                                                        showTime
                                                disabledDate={disableFutureDates}
                                                        placeholder={t("SelectStartDateandTime")}
                                                        onChange={onChange}
                                                        value={rangeStart}
                                                        renderExtraFooter={() => (
                                                            <div className="timePickerHeader">
                                                                <div>Hr</div>
                                                                <div>Min</div>
                                                                <div>Sec</div>
                                                            </div>
                                                        )}
                                                    />
                                                </Space>
                                                <Space direction="vertical">
                                                    <DatePicker
                                                        format="MM/DD/YYYY HH:mm:ss"
                                                        showTime
                                                disabledDate={disableFutureDates}
                                                        placeholder={t("SelectEndDateandTime")}
                                                        onChange={onChangeEndRange}
                                                        value={rangeEnd}
                                                        renderExtraFooter={() => (
                                                            <div className="timePickerHeader">
                                                                <div>Hr</div>
                                                                <div>Min</div>
                                                                <div>Sec</div>
                                                            </div>
                                                        )}
                                                    />
                                        </Space>
                                        <Tooltip title={t("Clicktosearchrequest")}>
                                            <button onClick={handleSearchRequest}
                                                className="search-button-icon"
                                                disabled={(rangeStart === '' || rangeStart === null) && (rangeEnd === '' || rangeEnd === null)}
                                            ><img src={searchIcon} /></button>
                                        </Tooltip>

                                        {resetDisplay ?   
                                            <Tooltip title={t("Clicktoreset")}>
                                                <button onClick={handleResetSearch} className="reload-button-icon"><img src={ReloadIcon} /></button>
                                            </Tooltip>
                                          : ''}
                                        </div>
                                   
                                    
                                </div>
                                <div className="view-report">
                                    <div>
                                        <Tooltip title={t("Clicktodownloadsupportrequest")}>
                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'transparent', color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => exportAsExcel(true)}>
                                                <img style={{ filter: 'invert(3) brightness(9)' }} src={XLSIcon} /></button>
                                        </Tooltip>
                                    </div>
                                    <Tooltip title={t("Clicktoloadsupportrequest")}>
                                        <RestartAltIcon onClick={() => openloadreport()} style={{ color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} />
                                    </Tooltip>
                                {roleName && roleName == "SuperAdmin" || roleName && roleName == "Developer" ?
                                    
                                              <div>
        
                                                  <Tooltip title={t("Clicktoviewsupportreport")}>
                                                      <EqualizerIcon onClick={() => opensupportreport()} style={{ color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} />
                                                  </Tooltip>
                                              </div>
                                          :
                                    ""
                                }
                                </div>
                            </div>
                            


                            <Collapse className="bg-color-orange ml-3 mr-3 mt-3"
                                defaultActiveKey={['2']}
                                items={[
                                    {
                                        key: '2',
                                        label: <div>{t("NewRequests")} <span className="float-right">{t("TotalCount")}: {assignedoptions.pagination.total}</span></div>,
                                        children: <div className="w-100 justify-content-center">
                                            <div className="Viewer-table index--table position-relative blank-row-tb common-table mb-4">
                                            <Table
                                                    className='table-responsive antd--detail-table1 support assgin-table'
                                                columns={assignedcolumns}
                                                dataSource={assignedtable}
                                                pagination={assignedoptions.pagination.total > REACT_APP_SUPPORT_PAGESIZE ? assignedoptions.pagination : false}
                                                onChange={handleAssignedChange}
                                                // scroll={{ x: "250px" }}
                                                paginationTotalRows={total}
                                            />
                                        </div>
                                        </div>,
                                    },
                                ]}
                            />

                            <Collapse className="bg-color-orange ml-3 mr-3 mt-3"
                                defaultActiveKey={['3']}
                                items={[
                                    {
                                        key: '3',
                                        label: <div>{t("OpenRequests")} <span className="float-right">{t("TotalCount")}: {acknowledgedoptions.pagination.total}</span></div>,
                                        children: <div className="w-100 justify-content-center"><div className="Viewer-table index--table position-relative blank-row-tb common-table mb-4">
                                            <Table
                                                className='table-responsive antd--detail-table1 support'
                                                columns={acknowledgedcolumns}
                                                dataSource={acknowledgedtable}
                                                pagination={acknowledgedoptions.pagination.total > REACT_APP_SUPPORT_PAGESIZE ? acknowledgedoptions.pagination : false}
                                                onChange={handleAcknowledgedChange}
                                                //  scroll={{ x: "250px" }}
                                                paginationTotalRows={total}
                                            />
                                        </div>
                                        </div>,
                                    },
                                ]}
                            />

                            <Collapse className="bg-color-orange ml-3 mr-3 mt-3"
                                defaultActiveKey={['3']}
                                items={[
                                    {
                                        key: '3',
                                        label: <div>{t("ClosedRequests")}<span className="float-right">{t("TotalCount")}: {closedoptions.pagination.total}</span></div>,
                                        children: <div className="w-100 justify-content-center"><div className="Viewer-table index--table position-relative common-table mb-4">
                                            <Table
                                                className='table-responsive antd--detail-table1 support'
                                                columns={closedcolumns}
                                                dataSource={closedtable}
                                                pagination={closedoptions.pagination.total > REACT_APP_SUPPORT_PAGESIZE ? closedoptions.pagination : false}
                                                onChange={handleClosedChange}
                                                //  scroll={{ x: "250px" }}
                                                paginationTotalRows={total}
                                            />
                                        </div>
                                        </div>,
                                    },
                                ]}
                            />
                        </section>
                    </Layout>

                    <Modal
                        show={updateModal}
                        onHide={handleUpdateModalClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-dialog-copy"
                    >
                        <Modal.Body>
                            <div class="copy-model">
                                <h5> {t("Areyousureyouwanttoupdatethestatus")}</h5>
                                <div class="w-100 mt-3 btn-yesno d-flex justify-content-end">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => UpdateStatusData(supportId)}
                                    >
                                        {t("Yes")}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary ml-2"
                                        onClick={handleUpdateModalClose}
                                    >
                                        {t("No")}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={applyModal}
                        onHide={handleApplyModalClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-dialog-copy"
                    >
                        <Modal.Body>
                            <div class="copy-model">
                                <h5> {t("Areyousureyouwanttoupdate?")}</h5>
                                <div class="w-100 mt-3 btn-yesno d-flex justify-content-end">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => UpdatePriority(applyId)}
                                    >
                                        {t("Yes")}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary ml-2"
                                        onClick={handleApplyModalClose}
                                    >
                                        {t("No")}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={acknowledgeModal}
                        onHide={handleAcknowledgeModalClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-dialog-copy"
                    >
                        <Modal.Body>
                            <div class="copy-model">
                                <h5> {t("Areyousureyouwanttoacknowledgethesupportrequest?")}</h5>
                                <div class="w-100 mt-3 btn-yesno d-flex justify-content-end">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => UpdateAssignedData(acknowledgeId)}
                                    >
                                        {t("Yes")}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary ml-2"
                                        onClick={handleAcknowledgeModalClose}
                                    >
                                        {t("No")}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={supportAttachmentModal}
                        onHide={handleAcknowledgeModalClose}
                        backdrop="static"
                        keyboard={false}
                        size="sm"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: '1rem' }}>{t("SupportAttachments ")}</Modal.Title>
                            <button type="button" className="close" aria-label="Close" onClick={handleModalClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body className="d-flex justify-content-center align-items-center">

                            <div>
                                {isImage(AttachmentSrc) ? (
                                    <Tooltip title={t("PreviewImages")}>
                                        <button onClick={() => handleCarouselImageClick(Attachment.IMAGE)} className="btn btn-primary mr-2">
                                            <CollectionsIcon style={{ color: '#fff', height: '25px', width: '25px', cursor: 'pointer' }}  ></CollectionsIcon>
                                        </button>
                                    </Tooltip>
                                ) : ''}
                                {isVideo(AttachmentSrc) ? (
                                    <Tooltip title={t("PreviewVideos")}>
                                        <button onClick={() => handleCarouselImageClick(Attachment.VIDEO)} className="btn btn-primary mr-2">
                                            <OndemandVideoIcon style={{ color: '#fff', height: '25px', width: '25px', cursor: 'pointer' }} ></OndemandVideoIcon>
                                        </button>
                                    </Tooltip>

                                ) : ''}
                                {isDocument(AttachmentSrc) ? (
                                    <Tooltip title={t("PreviewDocuments")}>
                                        <button onClick={() => handleCarouselImageClick(Attachment.DOCUMENT)} className="btn btn-primary mr-2">
                                            <PictureAsPdfIcon style={{ color: '#fff', height: '25px', width: '25px', cursor: 'pointer' }} ></PictureAsPdfIcon>
                                        </button>
                                    </Tooltip>
                                ) : ''}

                                {/*{*/}
                                {/*    AttachmentSrc && AttachmentSrc.length > 0 && isAudio(AttachmentSrc) ? (*/}
                                {/*        <Tooltip title="Listen Audio">*/}
                                {/*            <button onClick={() => playAudio(AttachmentSrc[0].source, Attachment.VOICE)} className="btn btn-primary">*/}
                                {/*                <MicIcon style={{ color: '#fff', height: '25px', width: '25px', cursor: 'pointer' }} />*/}
                                {/*            </button>*/}
                                {/*        </Tooltip>*/}
                                {/*    ) : null*/}
                                {/*}*/}
                                {isAudio(AttachmentSrc) ? (
                                    <Tooltip title={t("ListenAudio")}>
                                        <button onClick={() => handleCarouselImageClick(Attachment.VOICE)} className="btn btn-primary">
                                            <MicIcon style={{ color: '#fff', height: '25px', width: '25px', cursor: 'pointer' }} ></MicIcon>
                                        </button>
                                    </Tooltip>
                                ) : ''}
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showCarouselModal} size='lg'>
                        <Modal.Header closeButto>
                            <Modal.Title>{t("Attachments")}</Modal.Title>
                            <button type="button" className="close" aria-label="Close" onClick={handleCarouselModalClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="carousel-container">
                                {attachmentType && attachmentType == Attachment.IMAGE ? (
                                    <>
                                        <Carousel activeIndex={currentIndex} onSelect={handleSelect} slide={false} interval={null} prevLabel={null} nextLabel={null} controls={AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId == attachmentType).length > 1}>
                                            {AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId === attachmentType).map((attachment, index) => (
                                                <Carousel.Item key={index}>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ maxWidth: '95%' }}>
                                                            <Tooltip title={t("Download")}><button className="btn btn-primary mb-2 float-right" onClick={() => onDownloadButtonClick(attachment.source)}><FileDownloadOutlinedIcon fontSize="medium" /></button></Tooltip>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <img
                                                                style={{ width: '100%', maxWidth: '90%' }}
                                                                src={attachment.source}
                                                                alt={`Attachment ${index + 1}`}
                                                            />
                                                        </div>
                                                    </div>

                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </>
                                ) : attachmentType && attachmentType == Attachment.VIDEO ? (
                                    <Carousel activeIndex={currentIndex} onSelect={handleSelect} slide={false} interval={null} prevLabel={null} nextLabel={null} controls={AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId == attachmentType).length > 1}>
                                        {AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId === attachmentType).map((attachment, index) => (
                                            <Carousel.Item key={index} className="text-center">
                                                <video controls style={{ width: '100%', maxWidth: '90%', height: 'auto' }}>
                                                    <source src={attachment.source} alt={`Attachment ${index + 1}`} type="video/mp4" />
                                                    {t("Yourbrowserdoesnotsupportthevideotag.")}
                                                </video>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>

                                ) : attachmentType && attachmentType == Attachment.DOCUMENT ? (
                                    <Carousel activeIndex={currentIndex} onSelect={handleSelect} slide={false} interval={null} prevLabel={null} nextLabel={null} controls={AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId == attachmentType).length > 1}>
                                        {AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId === attachmentType).map((attachment, index) => (
                                            <Carousel.Item key={index} className="text-center">
                                                <iframe
                                                    src={attachment.source}
                                                    title="PDF Viewer"
                                                    className="pdf-viewer"
                                                    style={{ width: '90%', height: '70vh', border: 'none' }}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>

                                ) : attachmentType && attachmentType == Attachment.VOICE ? (
                                    <Carousel activeIndex={currentIndex} onSelect={handleSelect} slide={false} interval={null} prevLabel={null} nextLabel={null} controls={AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId == attachmentType).length > 1}>
                                        {AttachmentSrc && AttachmentSrc.filter(attachment => attachment.fileTypeId === attachmentType).map((attachment, index) => (
                                            <Carousel.Item key={index} className="text-center">
                                                <audio controls style={{ width: '90%' }} onClick={() => playAudio(attachment.source)}>                                                 
                                                    <source src={attachment.source} type="audio/mpeg" />
                                                    {t("Yourbrowserdoesnotsupporttheaudioelement.")}
                                                </audio>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>

                                ) : (
                                    // Render default content when attachmentType is neither 1 nor 2
                                    <div>{t("Noattachmentstodisplay")}</div>
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>

                </>
            }

            
        </div>
    );
}

export default connect()(SupportIndex);
