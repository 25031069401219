// Server details
//export const BASE = 'https://localhost:44366'
export const BASE = 'https://tekaversebeta.azurewebsites.net'
//export const BASE = 'https://tekaversetest.azurewebsites.net'


export const BASE_URI = `${BASE}`

// Authentication 
export const POST_LOGIN = `${BASE_URI}/User/SignIn`
export const GETDASHBOARD_URL = `${BASE_URI}/User/RedirecttoDashBoard`
export const GETAPPS_DATA = `${BASE_URI}/User/getAllApps`
export const GETSITEAPPS_DATA = `${BASE_URI}/Company/GetAllAppsData`
export const VALIDATE_USER = `${BASE_URI}/User/ValidateUser`
export const LOGINAS_USER = `${BASE_URI}/User/LoginAsUser`


// Change Password
export const POST_CHANGEPASSWORD = `${BASE_URI}/User/ChangePassword`

//Email Verification
export const GET_EMAILVERIFICATION = `${BASE_URI}/User/VerifyEmail`

// SendUserEmail
export const GET_SENDUSEREMAIL = `${BASE_URI}/User/SendUserEmail`
//Forgot Password
export const ADD_UPDATEPASSWORD = `${BASE_URI}/User/UpdatePassword`

//Access
export const GET_ALLPROJECTS = `${BASE_URI}/User/GetAllProjects`

//Users
export const GET_ALLUSERS = `${BASE_URI}/User/GetUsers`
export const POST_USERSANDAPPS = `${BASE_URI}/User/AddUsersAndApps`
export const GET_USERROLESANDMENUS = `${BASE_URI}/User/getUserMenuDetails`
export const GET_USERROLENAME = `${BASE_URI}/User/getRoleData`
export const GET_USERSWITHROLES = `${BASE_URI}/User/GetUserWithRoles`
export const GET_USERSWITHCOMPANIES = `${BASE_URI}/User/GetUserWithCompanies`
export const POST_ADDUSER = `${BASE_URI}/User/AddUser`
export const GET_USERBYID = `${BASE_URI}/User/GetUserById`
export const UPDATE_USER_DATA = `${BASE_URI}/User/UpdateUser`
export const DELETE_USERTYPE = `${BASE_URI}/User`
export const VERIFY_SEND_MESSAGE = `${BASE_URI}/User/VerifySendMessage`
export const GET_SUBCOMPANIESBYUSERID = `${BASE_URI}/User/GetSubcompaniesByUserId`
export const POST_ADDUSERCOMPANY = `${BASE_URI}/User/AddUserSubCompany`
export const GET_USERCOMPANIESBYID = `${BASE_URI}/User/GetUserCompanyById`
export const UPDATE_USER_COMPANY = `${BASE_URI}/User/UpdateUserCompany`
export const DELETE_USERCOMPANY = `${BASE_URI}/User/DeleteUserCompany`
export const GET_ALLACTIVITYLOGS = `${BASE_URI}/User/GetAllActivityLogsData`
export const GET_ALLWORKCATEGORY = `${BASE_URI}/User/GetAllWorkCategory`
export const GET_ALLAPPS = `${BASE_URI}/User/GetAllApps`
export const POST_PROJECTS = `${BASE_URI}/User/AddProjects`
export const GET_PROJECTBYID = `${BASE_URI}/User/GetProjectById`
export const UPDATE_PROJECT_DATA = `${BASE_URI}/User/UpdateProjectData`
export const GET_ALL_SESSION_LOGS = `${BASE_URI}/User/GetAllSessionLogsData`
export const GET_Logout = `${BASE_URI}/User/LogoutUser`
export const GET_REFRESHTIME = `${BASE_URI}/User/UpdateRefreshTime`
export const GET_IDLETIME = `${BASE_URI}/User/GetIdleTimeout`
export const UPDATE_MYPROFILE = `${BASE_URI}/User/UpdateMyProfile`
export const GET_USERACCESSLEVELS = `${BASE_URI}/User/GetUserAccessLevels`
export const UPDATE_USERLANGUAGE = `${BASE_URI}/User/UpdateLanguage`


// Company
export const GET_ALLCOMPANIES = `${BASE_URI}/Company/GetAllCompanies`
export const POST_ALLCOMPANIES = `${BASE_URI}/Company/AddCompany`
export const UPDATE_COMPANY_DATA = `${BASE_URI}/Company/UpdateCompany`
export const UPDATE_COMPANY_SITES = `${BASE_URI}/Company/UpdateCompanySites`
export const GET_COMPANYBYID = `${BASE_URI}/Company/GetCompanyById`
export const GET_SITESBYCOMPANYID = `${BASE_URI}/Company/GetSitesByCompanyId`
export const POST_COMPANYTHEME = `${BASE_URI}/Company/AddTheme`
export const DELETE_COMPANYTYPE = `${BASE_URI}/Company`
export const GET_COMPANYWITHSITES = `${BASE_URI}/Company/GetCompanyWithSites`
export const GET_SUBCOMPANYWITHSITES = `${BASE_URI}/Company/GetSubCompanySitesDetails`
export const GET_COMPANYSITESBYID = `${BASE_URI}/Company/GetCompanySiteById`
export const DELETE_COMPANYSITES = `${BASE_URI}/Company/DeleteCompanySites`
export const GET_TIMEZONES = `${BASE_URI}/Company/GetTimeZones`
export const GET_ALLRETENTIONDATA = `${BASE_URI}/Company/GetRetentionData`
export const GET_BANNERDATA = `${BASE_URI}/Company/GetBannerData`
export const POST_BANNERS = `${BASE_URI}/Company/AddBanners`
export const GET_BANNERBYID = `${BASE_URI}/Company/GetBannerById`
export const UPDATE_BANNER_DATA = `${BASE_URI}/Company/UpdateBannerData`
export const GET_COMPANYDATABYSITEID = `${BASE_URI}/Company/GetCompanyDataSiteById`
export const GET_SUBCOMPANYBYUSERID = `${BASE_URI}/Company/GetSubCompaniesByUser`

// Notification
export const GET_NOTIFICATIONDATA = `${BASE_URI}/Notification/GetNotificationData`

//Roles
export const GET_ALLROLES = `${BASE_URI}/User/GetAllRoles`

//Site
export const GET_ALLSITES = `${BASE_URI}/Company/GetAllSites`
export const POST_ALLSITES = `${BASE_URI}/Company/AddSites`
export const GET_SITEBYID = `${BASE_URI}/Company/GetSiteById`
export const DELETE_SITETYPE = `${BASE_URI}/Company/DeleteSite`
export const UPDATE_SITE_DATA = `${BASE_URI}/Company/UpdateSiteType`
export const GET_SITESBYUSERID = `${BASE_URI}/User/GetSitesByUserId`
export const GET_ALLSITESDETAILS = `${BASE_URI}/Company/GetAllSitesDetails`
export const GET_ALLSITESWITHRESELLER_COMPANY = `${BASE_URI}/Company/GetAllSitesWithResellerCompany`
export const GET_SUPPORTASSOCIATEDSITES = `${BASE_URI}/Company/GetSupportGroupSites`


// CompanyRelation
export const GET_ALLCOMPANYRELATION = `${BASE_URI}/Company/GetCompanyRelationDetails`
export const POST_COMPANYRELATION = `${BASE_URI}/Company/AddCompanyRelation`
export const UPDATE_MAPPING_DATA = `${BASE_URI}/Company/UpdateCompanyRelation`
export const GET_COMPANYRELATIONBYID = `${BASE_URI}/Company/GetCompanyRelationById`
export const DELETE_MAPPING = `${BASE_URI}/Company/DeleteMapping`
export const GET_ALLSUBCOMPANIES = `${BASE_URI}/Company/GetAllSubCompanies`
export const GET_SITESBYUSERCOMPANYID = `${BASE_URI}/Company/GetSitesByUserCompanyId`
export const POST_COMPANIESSITES = `${BASE_URI}/Company/AddCompanySites`


// UserSite

export const GET_USERSITERELATION = `${BASE_URI}/User/GetUserSiteDetails`
export const POST_USERSITERELATION = `${BASE_URI}/User/AddUserSiteRelation`
export const UPDATE_USERSITERELATION = `${BASE_URI}/User/UpdateUserSiteRelation`
export const DELETE_USERSITERELATION = `${BASE_URI}/User/DeleteUserSite`
export const GET_USERSITEBYID = `${BASE_URI}/User/GetUserSiteById`

// WorkItems

export const GET_ALLDEVELOPERS = `${BASE_URI}/WorkItem/GetAllDevelopers`
export const POST_ALLWORKITEMS = `${BASE_URI}/WorkItem/AddWorkItem`
export const GET_ALLWORKITEMS = `${BASE_URI}/WorkItem/GetAllWorkItems`
export const GET_ALLWORKITEMSSP = `${BASE_URI}/WorkItem/GetAllWorkItemsSP`
export const POST_DEVELOPERDETAILS = `${BASE_URI}/WorkItem/AddDeveloperDetails`
export const GET_DEVELOPERCOMMENTS = `${BASE_URI}/WorkItem/GetAllDeveloperComments`
export const GET_WORKITEMBYID = `${BASE_URI}/WorkItem/GetWorkItemById`
export const UPDATE_DEVELOPERCOMMENTS = `${BASE_URI}/WorkItem/EditDeveloperComments`
export const UPDATE_WORKITEMS = `${BASE_URI}/WorkItem/UpdateWorkItem`
export const ADD_WORKITEMSEARCH = `${BASE_URI}/WorkItem/AddWorkItemSearch`
export const REMOVE_WORKITEMSEARCH = `${BASE_URI}/WorkItem/RemoveSaveSearch`
export const GET_ALLVERSIONSUMMARY = `${BASE_URI}/WorkItem/GetAllVersionSummary`
export const UPDATE_VERSIONSUMMARY = `${BASE_URI}/WorkItem/UpdateVersionSummary`
export const GET_WORKITEMSHAVINGSUPPORT = `${BASE_URI}/WorkItem/GetWorkItemHavingSupport`


// TEKaControllers

export const GET_ALLTEKACONTROLLER = `${BASE_URI}/Company/GetAllTekacontrollers`
export const POST_TEKACONTROLLERS = `${BASE_URI}/Company/AddTEKaController`
export const GET_TEKACONTROLLERBYID = `${BASE_URI}/Company/GetTekacontrollerById`
export const UPDATE_TEKACONTROLLER_DATA = `${BASE_URI}/Company/UpdateTekacontroller`
export const DELETE_TEKACONTROLLER = `${BASE_URI}/Company/DeleteTekacontroller`

// Support
export const GET_ALLSUPPORTDATA = `${BASE_URI}/Support/GetSupportData`
export const GET_USERSFROMNOTIFICATION = `${BASE_URI}/Support/GetUsersHasSupportAccess`
export const UPDATE_PRIORITY = `${BASE_URI}/Support/UpdatePriority`
export const UPDATE_ASSIGNEDDATA = `${BASE_URI}/Support/UpdateAssignedData`
export const UPDATE_STATUSDATA = `${BASE_URI}/Support/UpdateStatus`
export const GET_SUPPORTLOGS = `${BASE_URI}/Support/GetSupportLogsData`
export const GET_SUPPORTGROUPLOGS = `${BASE_URI}/Support/GetSupportGroupLogs`
export const GET_COMPANYSUPPORT = `${BASE_URI}/Support/GetCompanyforsupport`
export const GET_SUPPORTREQUESTRATIO = `${BASE_URI}/Support/GetSupportRequestRatio`
export const GET_SUPPORTREQUEST_HISTORY = `${BASE_URI}/Support/GetSupportRequestLogs`
export const DELETE_GROUP = `${BASE_URI}/Support/DeleteGroup`
export const GET_SUPPORTGROUPUSERS = `${BASE_URI}/Support/GetUsersForSupportGroup`


// Dashboard

export const GET_SITES_WITH_ALARMS = `${BASE_URI}/Dashboard/GetDashboardData`

// CallOrder
export const GET_ALLCALLORDERDATA = `${BASE_URI}/Support/GetCallOrderData`
export const GET_SUPPORTGROUPDATA = `${BASE_URI}/Support/GetSupportGroupData`
export const POST_CALLORDERDATA = `${BASE_URI}/Support/AddCallOrder`
export const GET_NOTIFICATIONDATABYID = `${BASE_URI}/Support/GetCallOrderById`
export const UPDATE_CALLORDERDATA = `${BASE_URI}/Support/UpdateCallOrderData`
export const DELETE_CALLORDER = `${BASE_URI}/Support/DeleteCallOrder`
export const UPDATE_USERNAME = `${BASE_URI}/Support/UpdateUserName`
export const UPDATE_GROUPUSERNAME = `${BASE_URI}/Support/UpdateGroupUserName`
export const UPDATE_GROUPNAME = `${BASE_URI}/Support/UpdateGroupName`
export const POST_GROUPANDUSERS = `${BASE_URI}/Support/AddGroupAndUsers`
export const POST_USERSINOLDGROUP = `${BASE_URI}/Support/AddUsersInOldGroup`
export const GET_ALLGROUPS = `${BASE_URI}/Support/GetAllGroups`
export const GET_USERSBYGROUPID = `${BASE_URI}/Support/GetUsersByGroupId`
export const UPDATE_SUPPORTONCALENDAR = `${BASE_URI}/Support/UpdateSupportOnCanlendar`

// Evnets
export const POST_SUPPORTEVENT = `${BASE_URI}/Events/Add`
export const UPDATE_SUPPORTEVENT = `${BASE_URI}/Events/Update`
export const DELETE_SUPPORTEVENT = `${BASE_URI}/Events/Delete`
export const GET_SUPPORTEVENT = `${BASE_URI}/Events/GetEventsByGroupId`
export const GET_ALLEVENTS = `${BASE_URI}/Events/GetAllEvents`
export const GET_ALLTODAYSEVENTS = `${BASE_URI}/Events/GetTodaysEvents`
export const GET_ASSIGNEDTECHNICIANS = `${BASE_URI}/Events/GetAllAssignedTechnician`