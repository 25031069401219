import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import Layout from '../Layout';
import Loader from "../loader";
import { fetchUserMenudata, fetchProjectById } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { saveallprojects, updateprojectdata } from '../../Redux/Actions/AccessibilityActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";

const AddProject = () => {
    const { t } = useTranslation('addProjects');
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [checked, setChecked] = useState(true);
    const [isActive, setisActive] = useState(true);
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
   
    const [count, setCount] = useState(0);
    const [currentId, setCurrentId] = useState(null);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        getUserDetails();
    }, [])

    useEffect(() => {
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchProjectById(urlData[3]).then(
                response => {                    
                    setName(response.data.data.name);
                    setDescription(response.data.data.description)                   
                    setisActive(response.data.data.isActive)
                   
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
       
    }, [currentId])

    const handleChange = () => {        
        setisActive(!isActive)
    }

    let pageButton = t("Update")
    if (!currentId) {
        pageButton = t("Save")
    }
    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {

                dispatch(getuserroleandmenu(response.data.data))
               
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const addAllProjects = async (params) => {
        setLoading(true)
        if (params.name) {
            const response = await axios.post(url.POST_PROJECTS, {
                Name: params.name.trim(),
                Description: params.description.trim(),  
                IsActive: isActive,

            }).then(response => {
                dispatch(saveallprojects(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("Projectalreadyadded"), {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(t("ProjectCreatedsuccessfully"), {
                        className: "toastSuccess",
                    },);
                    history.push('/Projects');
                    setLoading(false);
                    setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }

    }

    const updateProjectType = async (params) => {
        setLoading(true)       
        if (params.name) {
            const response = await axios.put(url.UPDATE_PROJECT_DATA, {
                AppId: currentId,
                Name: params.name.trim(),
                Description: params.description.trim(),               
                isActive: isActive,               
            }).then(response => {
                dispatch(updateprojectdata(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("Projectalreadyadded"), {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(t("Projectupdatedsuccessfully"), {
                        className: "toastSuccess",
                    },);
                    history.push('/Projects');
                    setLoading(false);
                    setDisable(true);
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }

    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : '',
            description: description ? description : '',


        },

        validationSchema: Yup.object({
            name: Yup.string().required(t("PleaseEnterName")).matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                t("PleaseentervalidName")),



            description: Yup.string().required(t("PleaseEnterDescription")).matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Description"
            ),


        }),
        onSubmit: (values) => {
            { currentId == undefined || currentId == null || currentId == isNaN ? addAllProjects(values) : updateProjectType(values) };
        }
    })

    return (
        <>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/Projects">
                                        <img src={Arrow_leftbrown}></img>
                                        {t("Back")}
                                    </Link>
                                </h4>

                            </div>
                            <div className="content-area">
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                    }} >

                                    <div className="col-lg-12 wk-form-grup">
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("ProjectName")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="name" className="form-control" placeholder={t("EnterName")}
                                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setName(e.target.value) }}
                                                            onBlur={validation.handleBlur} value={validation.values.name}
                                                            invalid={validation.touched.name && validation.errors.name ? true : false}
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("Description")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="description" className="form-control" placeholder={t("EnterDescriptionHere")}
                                                            type="textarea" maxLength="250"

                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                                setCount(e.target.value.length);
                                                                setDescription(e.target.value.replace(/\s+/g, ' '))
                                                            }}
                                                            onBlur={validation.handleBlur} value={validation.values.description}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        <small className="count text-left">{count}/250</small>
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>









                                            <div className="col-md-12 mb-6">

                                                <div className="form-checkbox ">
                                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                                        <label>
                                                            <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> {t("Active")}</label> :

                                                        <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                            {t("Active")}</label>}
                                                </div>

                                            </div>
                                            <div className="col-md-12 mb-8">
                                                <button type="submit" className="btn btn-primary" disabled={disable}>{pageButton}</button>
                                                <Link className="btn btn-bordered ml-2" to="/Projects">{t("Cancel")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
        </>
            )


}

export default connect()(AddProject);

