import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchUsersWithCompanies, fetchUserCompanyById, fetchUserMenudata, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Add from "../../assets/images/Add.svg";
import Layout from '../Layout';
import { Tooltip } from 'antd';
import { getusercompanydata, deleteusercompanydata } from '../../Redux/Actions/UserCompanyAction';
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import searchIcon from "../../assets/images/SearchIcon.svg";
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";

const UserCompanyIndex = () => {
    const { t} = useTranslation('userCompany');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;   
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);  // dynamic
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['50', '100'],
            showSizeChanger: true,
        },
    });
   // const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [comRoleName, setComRoleName] = useState(null);
    const [search, setSearch] = useState("")
    const [searchData, setSearchData] = useState()
    const [searchClicked, setSearchClicked] = useState(false)
    const [searchError, setSearchError] = useState()
    const [userName, setUserName] = useState("")
    const [resetDisplay, setResetDisplay] = useState(false);
   
    useEffect(() => {      
            setLoading(false)             
        getallUserswithcompanies();
        getRoleDetails();

    }, [])
   
    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
               // setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setComRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }



    const getallUserswithcompanies = (isReset = false) => {        
        setLoading(true)
        fetchUsersWithCompanies({
            UserId: loggedUserId,
            UserName: isReset ? "" : userName
        })
            .then(response => {
                dispatch(getusercompanydata(response.data))
                setTable(response.data.data)               
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
            })
            .catch((err) => {
                console.log("Err: ", err);
            });            
            setLoading(false)         
    }

    const SearchControl = async () => {
        if (userName !== '') {
            setResetDisplay(true);
        }

        else if (userName == '' && resetDisplay) {
            setResetDisplay(false);
        }
        getallUserswithcompanies();
    }

    const handleKeyPress = (e) => {
        
        if (e.key === "Enter") {
            
            SearchControl();

        }
    }

    const handleResetButton = () => {
        setResetDisplay(false);
        setUserName("")
        getallUserswithcompanies(true);
    }

    const deleteUserCompanyRecord = async (userid) => {
        debugger;
        setLoading(true)
        await axios.delete(url.DELETE_USERCOMPANY + '/' + userid, {
        }).then(response => {
            debugger;
            dispatch(deleteusercompanydata(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(t("SubCompanyDeletedSuccessfully"), {
                className: "toastSuccess",
            },);
            getallUserswithcompanies()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)



    }

    const { confirm } = Modal;
    const handleDelete = (userId) => {
        confirm({
            title: t('Areyousure'),
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            onOk() {
                deleteUserCompanyRecord(userId)
            },
            onCancel() {
            },
        });
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const bindCompany = (userCompany) => {

        return userCompany.map(x => x.companyName).join(", ");
    }

    const handleEdit = (userId) => {
        history.push('/UserCompany/edit/' + userId)
    }

    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            className: "id-count",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}` },

        },

        {
            title: t("Name"),
            dataIndex: "name",
            className: "names-count",
            render: (data, user) => (user.lastName + " " + user.firstName),
        },

        {
            title: t("SubCompanies"),
            dataIndex: "companies",
            className: "subcompany" ,
            render: (data, user) => (
                user.companies ?
                    <div className="ellips-dec">
                        <Tooltip title={bindCompany(user.companies)}>{bindCompany(user.companies)}</Tooltip></div>
                    : "NA"
            ),
        },

        ...(comRoleName == "SuperAdmin" ?
        [
        {
            title: t("Action"),
            dataIndex: "",
            render: (data, companyId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.userId)}></img>
            </button>    
            </>)
            },
            ] : []
        )  
    ]

    return (
        <div>
            {comRoleName == null ? <Loader /> : comRoleName && comRoleName != "SuperAdmin" && comRoleName && comRoleName != "Developer"
                && comRoleName && comRoleName != "Admin" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                <Layout>
                    <section className="Company_section common ">
                        <Toaster />
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">{t("UserCompanyRelation")}</h4>
                                <div className="ml-auto d-flex flex-wrap right-section">
                                    <div className='d-flex position-relative searchBox' > <input type="text" className='form-control searchQR' value={userName} placeholder={t("SearchByUserName")}
                                        onChange={(e) => {
                                            setUserName(e.target.value.trimLeft());
                                        }}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    ></input>
                                        {userName == "" ?
                                            <button onClick={() => SearchControl()} disabled className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button> :
                                            <button onClick={() => SearchControl()} className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button>
                                        }

                                    </div>
                                        {userName == "" && !resetDisplay ? "" :
                                            <button className='btn btn-primary d-flex align-items-center btn-new' onClick={() => handleResetButton()} >{t("Reset")}</button>
                                        }
                                    <Link className="btn btn-primary d-flex align-items-center btn-new" to="/UserCompany/Add"><img src={Add}></img>{t("AddCompany")}</Link>
                                </div>

                            </div>

                            <div className="Viewer-table index--table position-relative common-table mb-4">
                              
                                <Table
                                    className='antd--detail-table1'
                                    columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}                                    
                                    onChange={handleChange}
                                    paginationTotalRows={total}
                                />
                            </div>
                        </div>
                    </section>
                    </Layout>
                </>
            }
        </div>
    )
}
export default connect()(UserCompanyIndex);
