import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Add from "../../assets/images/Add.svg";
import Layout from '../Layout';
import { Tooltip } from 'antd';
import { fetchUserSiteRelation, fetchUserMenudata, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getusersitedata, deleteusersitedata } from '../../Redux/Actions/UserSiteAction';
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import searchIcon from "../../assets/images/SearchIcon.svg";
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";

const UserSiteIndex = () => {
    const { t} = useTranslation('userSite');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
   
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: [ '50', '100'],
            showSizeChanger: true,
        },
    });
   // const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [comRoleName, setComRoleName] = useState(null);
    const [userName, setUserName] = useState("")
    const [search, setSearch] = useState("")
    const [searchData, setSearchData] = useState()
    const [searchClicked, setSearchClicked] = useState(false)
    const [searchError, setSearchError] = useState()
    const [searchOptions, setSearchOptions] = useState({
        pagination: { current: 1 }, pageSize: pageSize
    })
    const [resetDisplay, setResetDisplay] = useState(false);

    useEffect(() => {
        //setTimeout(() => {
        //    setLoading(false)
        //}, 2000)
        setLoading(false)
        getRoleDetails();
        getallUserswithsites();
    }, [])

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
               // setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setComRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
       
    const handleKeyPress = (e) => {        
        if (e.key === "Enter") {            
            SearchControl();
        }
    }

    const handleResetButton = () => {
        setResetDisplay(false);
        setUserName("")
        getallUserswithsites(true);
    }
   
    const getallUserswithsites = (isReset = false) => {
        setLoading(true)
        fetchUserSiteRelation({
            UserId: loggedUserId,
            UserName: isReset ? "" : userName
        })
            .then(response => {
                dispatch(getusersitedata(response.data))
                setTable(response.data.data)                
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)       
    }

    const { confirm } = Modal;
    const handleDelete = (usersiteId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteUserSiteRecord(usersiteId)
            },
            onCancel() {
            },
        });
    }

    const deleteUserSiteRecord = async (mappingid) => {        
        setLoading(true)
        await axios.delete(url.DELETE_USERSITERELATION + '/' + mappingid, {
        }).then(response => {            
            dispatch(deleteusersitedata(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            // toast.success(response.data.message, {
            //     className: "toastSuccess",
            // },);
            toast.success(t("UserSiteRelationDeletedsuccessfully"), {
                className: "toastSuccess",
            },);
            getallUserswithsites()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)
    }

    const SearchControl = async () => {
        if (userName !== '') {
            setResetDisplay(true);
        }

        else if (userName == '' && resetDisplay) {
            setResetDisplay(false);
        }
        getallUserswithsites();
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const handleEdit = (userSiteId) => {
        history.push('/UserSite/edit/' + userSiteId)
    }

    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",

            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}` },

        },

        {
            title: t("UserName"),
            dataIndex: "userName",           
        },

        {
            title: t("SubCompany"),
            dataIndex: "companyName",
        },

        {
            title: t("SiteName"),
            dataIndex: "siteName",
        },

        {
            title: t("AllowNotification"),
            dataIndex: "allowNotification",
            render: (data) => (data ? "Yes" : "No"),
        },

        {
            title: t("AllowSMS"),
            dataIndex: "allowSMS",
            render: (data) => (data ? "Yes" : "No"),
        },

        {
            title: t("AllowUpdate"),
            dataIndex: "allowUpdate",
            render: (data) => (data ? "Yes" : "No"),
        },

        {
            title: t("AllowMaintennce"),
            dataIndex: "allowMaintenance",
            render: (data) => (data ? "Yes" : "No"),
        },

       
        ...(comRoleName == "SuperAdmin" ?
        [
        {
                title: t("Action"),
                className: "sitebuttons",
            dataIndex: "",
                render: (data, companyId) => (<>
                    <ul className="list-inline">
                        <li className="list-inline-item mr-0">
                    <button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.userSiteId)}></img>
                            </button>
                        </li>
                        <li className="list-inline-item mr-0">
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.userSiteId)}></img>
                            </button>
                        </li>
                        </ul>
                    </>)
            },
            ] : []
        )
    ]

    return (
        <div>
            {comRoleName == null ? <Loader /> : comRoleName && comRoleName != "SuperAdmin" && comRoleName && comRoleName != "Developer"
                && comRoleName && comRoleName != "Admin" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                <Layout>
                    <section className="Company_section common ">
                        <Toaster />
                            <div className="container usersite-container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">{t("UserSiteRelation")}</h4>
                                <div className="ml-auto d-flex flex-wrap right-section">
                                    <div className='d-flex position-relative searchBox' > <input type="text" className='form-control searchQR' value={userName} placeholder={t("SearchByNameCompanySite")}
                                        onChange={(e) => {
                                            setUserName(e.target.value.trimLeft());

                                        }}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    ></input>
                                        {userName == "" ?
                                            <button onClick={() => SearchControl()} disabled className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button> :
                                            <button onClick={() => SearchControl()} className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button>
                                        }
                                    </div>
                                        {userName == "" && !resetDisplay ? "" :
                                            <button className='btn btn-primary d-flex align-items-center btn-new' onClick={() => handleResetButton()} >{t("Reset")}</button>
                                        }
                                    <Link className="btn btn-primary d-flex align-items-center btn-new" to="/UserSite/Add"><img src={Add}></img>{t("AddUserSite")}</Link>
                                </div>
                            </div>
                            <div className="Viewer-table index--table position-relative common-table mb-4">
                              
                                <Table
                                    className='table-responsive antd--detail-table1'
                                    columns={columns}
                                        dataSource={table}
                                      //  pagination={options.pagination} 
                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}                                      
                                    onChange={handleChange}                                    
                                    paginationTotalRows={total}
                                />
                            </div>
                        </div>
                    </section>
                    </Layout>
                </>
            }
        </div>
    )
}
export default connect()(UserSiteIndex);

