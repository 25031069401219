/* eslint-disable array-callback-return */
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import ColorPicker from "../../assets/images/colorpicker.png";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import { ColorExtractor } from 'react-color-extractor'
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import { saveallcompanies, updatecompanydata } from '../../Redux/Actions/CompanyActions';
import { getallsites } from '../../Redux/Actions/SiteActions';
import UploadImage from "../UploadImages/UploadImage";
import { fetchCompanyById, fetchAllSites, fetchUserMenudata } from "../../Redux/Api/fetch.api";
import Layout from '../Layout';
import Loader from "../loader";
import PreviewIcon from "../../assets/images/preview-icon.svg";
import ResetColorIcon from "../../assets/images/resetcolor-icon.svg";
import FooterLeft from "../../assets/images/leftfooter.png";
import Previewlogo from "../../assets/images/preview-logo.png";
import { Checkbox, Select } from "antd";
import { ChromePicker } from 'react-color';
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
const Option = Select.Option;



const AddCompany = () => {
    const { t } = useTranslation('addCompany');
    const loggedInUserId = localStorage.getItem('UserId')
    const [isActive, setisActive] = useState(true)
    const [isOpen, setisOpen] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [isFooter, setisFooter] = useState(false)
    const [isReset, setIsReset] = useState(false)
    const [isBody, setisBody] = useState(false)
    const [isIcon, setIsIcon] = useState(false)
    const dispatch = useDispatch(); 
    const [companyName, setCompanyName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [headerId, setHeaderId] = useState("");
    const [originalheader, setOriginalHeader] = useState("");
    const [originalfooter, setOriginalFooter] = useState("");
    const [originalbody, setOriginalBody] = useState("");
    const [originalicon, setOriginalIcon] = useState("");
    const [footerId, setFooterId] = useState("");
    const [bodyId, setBodyId] = useState("");
    const [iconId, setIconId] = useState('#000000');
    const [logoName, setLogoName] = useState("");
    const [allSitesData, setAllSitesData] = useState([]);
    const [address, setAddress] = useState("");
    const [count, setCount] = useState(0);
    const [companyId, setCompanyId] = useState()
    const [colors, setColors] = useState(null);
    const [getRadioButton, setGetRadioButton] = useState({ show: "show", selected: "show", });    
    const [companyUrl, setCompanyUrl] = useState("");  
    const [companyError, setCompanyError] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(true);
    const [disable, setDisable] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [showHeaderPicker, setShowHeaderPicker] = useState(false);
    const [showFooterPicker, setShowFooterPicker] = useState(false);
    const [showBodyPicker, setShowBodyPicker] = useState(false);
    const [showIconPicker, setShowIconPicker] = useState(false);
    const [selectSites, setSelectSites] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [sites, setSites] = useState([]);
    const [siteError, setSiteError] = useState(false);
    const [ifUpdate, setifUpdate] = useState(false)
    let history = useHistory();
    const [mediaFiles, setMediaFiles] = useState();
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [userRoleData, setUserRoleData] = useState([]);
    const [companyLogo, setCompanyLogo] = useState({
        upload: {
            pictures: [],
            // maxFileSize: 5242880,
            imgExtension: [".jpg", ".png", ".jpeg"],
            defaultImages: [
            ]
        }
    })
    const [mediaTypeSelected, setMediaTypeSelected] = useState(false)
    const [imagesErr, SetImagesErr] = useState(false)
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [allAddress, getAllAddress] = useState('')
    const [postalCodeDisable, setPostalCodeDisable] = useState(false);
    const [fieldValue, setFieldsValue] = useState(false);
    const [addressError, setAddressError] = useState('');

    useEffect(() => {     
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchCompanyById(urlData[3]).then(
                response => {                        
                    setCompanyName(response.data.data.companyDetails.companyName);                   
                    setCity(response.data.data.companyDetails.city)
                    setState(response.data.data.companyDetails.state)
                    setCountry(response.data.data.companyDetails.country)
                    setPostalCode(response.data.data.companyDetails.postalCode)
                    setMediaFiles(response.data.data.companyDetails.companyLogo)
                    setCompanyId(response.data.data.companyDetails.companyId)
                    setAddress(response.data.data.companyDetails.address)      
                    setLatitude(response.data.data.companyDetails.latitude)
                    setLongitude(response.data.data.companyDetails.longitude)
                    setisActive(response.data.data.companyDetails.isActive)                    
                    setCount(response.data.data.companyDetails.address.length)
                    setHeaderId(response.data.data.colorCodeDetails.headerColorCode)
                    setOriginalHeader(response.data.data.colorCodeDetails.headerColorCode)
                    setFooterId(response.data.data.colorCodeDetails.footerColorCode)
                    setOriginalFooter(response.data.data.colorCodeDetails.footerColorCode)
                    setBodyId(response.data.data.colorCodeDetails.bodyColorCode)
                    setOriginalBody(response.data.data.colorCodeDetails.bodyColorCode)
                    setIconId(response.data.data.colorCodeDetails.iconColorCode == null ? "" : response.data.data.colorCodeDetails.iconColorCode);
                    setOriginalIcon(response.data.data.colorCodeDetails.iconColorCode == null ? "" : response.data.data.colorCodeDetails.iconColorCode)
                    setLoading(false)
                    setifUpdate(true);
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    useEffect(() => {       
        getUserDetails();
      
    }, [])

    const handleChange = () => {
        setisActive(!isActive)
    }
       
    const handleImagesChange = (files) => {
        setCompanyLogo(
            {
                ...companyLogo,
                upload: {
                    ...companyLogo.upload,
                    pictures: [...files]
                },
            },
            () => {
                console.warn("It was added!");
            }
        );
        SetImagesErr("")
        setHeaderId("")
        setFooterId("")
        setBodyId("")
        setIconId("")
    };

    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {               
                dispatch(getuserroleandmenu(response.data.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getColors = (detectedColorCodes) => {
        setColors(detectedColorCodes);
    };

    const OpenHeaderPicker = () => {
        
        setisOpen(!isOpen);
        setisFooter(false)
        setisBody(false);
        setIsIcon(false)
    };

    const OpenFooterPicker = () => {
        
        setisOpen(false);
        setisFooter(!isFooter);
        setisBody(false)
        setIsIcon(false)
    };

    const OpenBodyPicker = () => {
        setisOpen(false);
        setisFooter(false);
        setisBody(!isBody);
        setIsIcon(false)

    };

    const OpenIconPicker = () => {
        setisOpen(false);
        setisFooter(false);
        setisBody(false);
        setIsIcon(!isIcon)
        setShowIconPicker(true);

    };
      
    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
   
    const handleRemoveImgClick = () => {        
        setMediaFiles("")
        setColors("")
        setHeaderId("")
        setFooterId("")
        setBodyId("'")
        setOriginalHeader("")
        setOriginalFooter("")
        setOriginalBody("")
        setOriginalIcon("")
        SetImagesErr(true)
    };

    let pageButton =t( "Update")
    if (!currentId) {
        pageButton = t("Save")
    }

    const addCompanyInformation = async (e) => {
        if (!address) {
            setAddressError(t("Addressisrequired"));
            return;
        }
        else {

            if (bodyId) {
                setisBody(false)
            }
            if (footerId) {
                setisFooter(false)
            }
            if (headerId) {
                setisOpen(false)
            }

            if (iconId) {
                setIsIcon(false)
            }

            setLoading(true)
            const formData = new FormData();
            companyLogo.upload.pictures.forEach((mFile) => {
                var imageUrl = mFile.split(";");
                var contentType = imageUrl[0].split(":")[1];
                var realData = imageUrl[2].split(",")[1];
                // Convert to blob
                var blob = b64toBlob(realData, contentType);
                const _fileData = new File([blob], imageUrl[1].substring(5)); // passing name 
                formData.append("File", _fileData);
                //isMedia = true;
            })
            formData.append("CompanyName", companyName.trim());
            formData.append("State", state);
            formData.append("City", city);
            formData.append("PostalCode", postalCode);
            formData.append("Country", country);
            formData.append("Address", address);
            formData.append("ThemeName", companyName);
            formData.append("AppliedBy", loggedInUserId);
            formData.append("Latitude", latitude);
            formData.append("Longitude", longitude);
            formData.append("HeaderColorCode", headerId);
            formData.append("FooterColorCode", footerId);
            formData.append("BodyColorCode", bodyId);
            formData.append("IconColorCode", iconId);
            formData.append("IsActive", isActive);

            const response_ = await axios({
                method: 'post',
                url: url.POST_ALLCOMPANIES,
                data: formData,
            }).then(response => {
                if (response.data.success == false) {
                    toast.error(t("Companyalreadyexist"), {
                        className: "toastSuccess",
                    });
                }
                else {
                    dispatch(saveallcompanies(response.data))
                    toast.success(t("CompanyCreatedsuccessfully"), {
                        className: "toastSuccess",
                    });
                    history.push('/Company');
                    SetImagesErr('')
                }
                setLoading(false)
            })

                .catch((err) => {
                    debugger;
                    toast.error(err.response.data.message, {
                        className: "toastSuccess",
                    },);
                    setDisable(false);
                });
            setLoading(false)
        }
    }

    const updateCompanyInformation = async (e) => {
        if (!address) {
            setAddressError(t("Addressisrequired"));
            return;
        }
        else {
            if (bodyId) {
                setisBody(false)
            }
            if (footerId) {
                setisFooter(false)
            }
            if (headerId) {
                setisOpen(false)
            }

            if (iconId) {
                setIsIcon(false)
            }
            if (companyLogo.upload.pictures.length > 0 || mediaFiles != null) {
                
                setLoading(true)
                const formData = new FormData();
                var blob;
                //image
                {
                    companyLogo.upload.pictures.length > 0 ?
                        companyLogo.upload.pictures.forEach((mFile) => {
                            var imageUrl = mFile.split(";");
                            var contentType = imageUrl[0].split(":")[1];
                            var realData = imageUrl[2].split(",")[1];
                            // Convert to blob
                            blob = b64toBlob(realData, contentType);
                            const _fileData = new File([blob], imageUrl[1].substring(5)); // passing name 
                            formData.append("File", _fileData);
                            //isMedia = true;
                        })
                        :
                        blob = fetch(mediaFiles).then(r => r.blob());
                    const _fileData = new File([blob], mediaFiles); // passing name 
                    formData.append("CompanyLogo", _fileData.name);
                    setLogoName(_fileData.name)
                }
                formData.append("companyName", companyName.trim());
                formData.append("State", state);
                formData.append("City", city);
                formData.append("PostalCode", postalCode);
                formData.append("Country", country);
                formData.append("Address", address);
                formData.append("companyId", companyId)
                formData.append("Latitude", latitude == null ? "" : latitude);
                formData.append("Longitude", longitude == null? "" : longitude);
                // formData.append("companyLogo", companyUrl)
                formData.append("HeaderColorCode", headerId);
                formData.append("FooterColorCode", footerId);
                formData.append("BodyColorCode", bodyId);
                formData.append("IconColorCode", iconId);
                formData.append("IsActive", isActive);
                const response_ = await axios({
                    method: 'put',
                    url: url.UPDATE_COMPANY_DATA,
                    data: formData,
                }).then(response => {
                    if (response.data.success == false) {
                        toast.error(t("CompanyAlreadyExists"), {
                            className: "toastSuccess",
                        });
                        SetImagesErr('')
                    }
                    else {
                        dispatch(updatecompanydata(response.data))
                        toast.success(t("Companyupdatedsuccessfully"), {
                            className: "toastSuccess",
                        });
                        history.push('/Company')
                        SetImagesErr('')
                    }
                })
                setLoading(false)
            }
        }
    }

    const radioButtonValueChange = (e) => {
        if (e.target.value === "hide") {
            setGetRadioButton({
                show: "hide",
                selected: e.target.value,
            });
        } else {
            setGetRadioButton({
                show: "show",
                selected: e.target.value,
            });
        }
    };

    const handlePreview = () => {
        setIsPreview(true);
       
    }

    const handleReset = () => {
        setHeaderId(originalheader)
        setFooterId(originalfooter)
        setBodyId(originalbody)
        setIconId(originalicon)
        setIsReset(false)
       
    }

    const renderSwatches = () => {
        if (Array.isArray(colors) && colors?.length > 0) {
            const swatches = colors.map((color, id) => {
                return (
                    <div
                        key={id}
                        style={{
                            backgroundColor: color,
                            width: 40,
                            height: 40
                        }}
                    />
                );
            });
            return swatches;
        }
    };
    
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            companyName: companyName ? companyName : '',
           /* companyUrl: companyUrl ? companyUrl : '',*/
            city: city ? city : '',
            state: state ? state : '',
            country: country ? country : '',
            postalCode: postalCode ? postalCode : '',
            address: address ? address : '',
        },
          validationSchema: Yup.object({
              companyName: Yup.string().required(t("PleaseEnterName")).matches(
                  "^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$",
                  t("PleaseentervalidName")
              ),
              //postalCode: Yup.string().required('Please Enter Zip code').matches(
              //    /^\d{5,6}$/,
              //    "Please enter valid code "
              //),
              //city: Yup.string().required('Please Enter City ').matches(
              //    "^[A-Za-z ]+$",
              //    "Please enter valid City name"),
              //state: Yup.string().required('Please Enter State ').matches(
              //    "^[A-Za-z ]+$",
              //    "Please enter valid state"),
              //country: Yup.string().required('Please Enter Country ').matches(
              //    "^[A-Za-z][A-Za-z]*$",
              //    "Please enter valid Country"),

              //address: Yup.string().required('Please Enter Address ').matches(
              //    "^(.|\s)*[a-zA-Z]+(.|\s)*$",
              //    "Please enter valid Address"
              //)
          }),

        onSubmit: (values) => {
            if (!imagesErr) {
                { currentId == undefined || currentId == null || currentId == isNaN ? addCompanyInformation(values) : updateCompanyInformation(values) };
            }
        }       
    });

    const handleChangeComplete = (newColor) => {
        setHeaderId(newColor.hex);
        setShowPicker(false);
        setisOpen(false)
        
    };

    const togglePicker = () => {
        setShowPicker(!showPicker); // Toggle color picker visibility
    };


    const handleChangeFooter = (newColor) => {
       
        setFooterId(newColor.hex);
        setShowPicker(false);
        setisFooter(false)
    };

    const handleChangeBody = (newColor) => {
        setBodyId(newColor.hex);
        setShowPicker(false);
        setisBody(false)
    };

    const handleChangeIcon = (newColor) => {
        
        if (newColor && newColor.hex) {
            setIconId(newColor.hex);
            setShowPicker(false);
            setIsIcon(false)
        }
    };

    //const handleAddressChange = (value) => {
    //    if (value.length > 0) {
    //        setAddress(value)
    //    }
    //    else {
    //        setAddress("")
    //    }
    //};

    const handleAddressChange = (value) => {
        setAddress(value);
        if (value.length > 0) {
            setAddress(value);
            setAddressError(''); // Clear the error when there is a value
        } else {
            setAddress("")
            setAddressError(t("Addressisrequired")); // Set the error when the value is empty
        }
    };

    const handleAddressSelect = async (address, placeId) => {
        setAddressError('');
        setAddress(address);
        getAllAddress(address)
        const results = await geocodeByAddress(address);
        const latlang = await getLatLng(results[0]);
        console.log("data", latlang.lat)
        setLatitude(latlang.lat)
        setLongitude(latlang.lng)
        geocodeByAddress('Montevideo, Uruguay')
            .then(results =>

                getLatLng(results[0]))
            .then(({ lat, lng }) =>
                //setLatitude({lat}),
                console.log('Successfully got latitude and longitude', { lat, lng }),

            );
        const [place] = await geocodeByPlaceId(placeId);

        const { long_name: postalCode = "" } =
            place.address_components.find((c) => c.types.includes("postal_code")) ||
            {};
        let city = "",
            country = "",
            state = ""


        for (const component of results[0].address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "locality": {
                    city = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    state = component.long_name;
                    break;
                }
                case "country": {
                    country = component.long_name;
                    break;
                }
            }
        }

        setPostalCodeDisable(!!postalCode);
        setFieldsValue({

            ["postal_code"]: postalCode,
            ["city"]: city,
            ["state"]: state,
            ["country"]: country,
        });
        { address != "" ? setCity(city) : '' }
        setPostalCode(postalCode)
        setCity(city)
        setState(state)
        setCountry(country)
        console.log("postal", postalCode)
        console.log("city", city)
        console.log("state", state)
        console.log("country", country)
    };

    const handleStateChangeForAddress = (e) => {
        if (e == "") {
            setCity("")
            setPostalCode("")
            setState("")
            setCountry("")
            setLatitude("")
            setLongitude("")
            getAllAddress("")
        }
    }

    const toggleHeaderPicker = () => {
        setShowHeaderPicker(!showHeaderPicker);
    };

    const toggleFooterPicker = () => {
        setShowFooterPicker(!showFooterPicker);
    };

    const toggleBodyPicker = () => {
        setShowBodyPicker(!showBodyPicker);
    };

    const toggleIconPicker = () => {
        setShowIconPicker(!showIconPicker);
    };

    const renderFuncForGooglePlaces = ({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
    }) => (
        <div>
            <Input
                id="selectAddress_1"
                //addonAfter={selectAfter("address_1", publicOrPrivate("address_1"))}
                placeholder={t('SearchPlaces')}
                size="large"
                {...getInputProps({
                    placeholder: t('SearchPlaces'),
                })}
                tabIndex={9}
                maxLength={255}
            />

            <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                    const style = suggestion.active
                        ? {
                            backgroundColor: "#f8f8f8",
                            cursor: "pointer",
                        }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                        };
                    return (
                        <div
                            {...getSuggestionItemProps(suggestion, {
                                style,
                            })}
                            key={index}
                        >
                            {suggestion.description}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" &&  roleName && roleName != "Developer"? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="Company_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/Company">
                                        <img src={Arrow_leftbrown}></img>
                                        {t("Back")}
                                    </Link>
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12">
                                    <div className="content-area">

                                        <Form className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                if (companyLogo.upload.pictures == "" && mediaFiles == null) {
                                                    SetImagesErr(true);
                                                }
                                                return false;
                                            }}>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-sm-12 company-photo-container justify-content-center d-flex mb-3">
                                                        <div className="company--logo-wrap">
                                                            <div className="company--logo">
                                                                <div className="upload--btn">
                                                                    <UploadImage
                                                                        {...companyLogo.upload ? companyLogo.upload : ""}
                                                                        handleChange={handleImagesChange}
                                                                        mediaTypeSelected={mediaTypeSelected}
                                                                        setMediaTypeSelected={setMediaTypeSelected}
                                                                        buttonText={t("AddCompanyLogo")}
                                                                        images={true}
                                                                    />
                                                                </div>
                                                                <div className="uploaded_images-wrap">
                                                                    {
                                                                        mediaFiles ?
                                                                            <div className="img--box position-relative">
                                                                                <span
                                                                                    className="position-absolute"
                                                                                    onClick={(e) => handleRemoveImgClick(e)}
                                                                                >
                                                                                    X
                                                                                </span>
                                                                                <img src={mediaFiles} />
                                                                            </div> : ''
                                                                    }
                                                                </div>
                                                                {< small className="text-danger d-block mt-2 ms-5 text-center">{!imagesErr ? ' ' : t('PleaseAddimage')}</small>}
                                                            </div>
                                                        </div>
                                                        <div className="form-fields-col row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label>{t("CompanyName")} <span className="required-fields"> * </span> </label>
                                                                    <div className="input-group">
                                                                        <Input name="companyName" className="form-control" placeholder={t("EnterCompanyName")}
                                                                            type="text" maxLength={50} //onChange={validation.handleChange}
                                                                            onChange={(e) => { validation.handleChange(e), setCompanyName(e.target.value.replace(/\s{2}/g, '')) }}
                                                                            onBlur={validation.handleBlur} value={validation.values.companyName}
                                                                            invalid={
                                                                                validation.touched.companyName && validation.errors.companyName ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.companyName && validation.errors.companyName ? (
                                                                            <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                                                        ) : null}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="form-group autocomplete-places">
                                                                    <label>{t("AddressAutocomplete")}<span className="required-fields"> * </span></label>
                                                                    <div class="row">
                                                                        <div className="col-md-12">
                                                                            <PlacesAutocomplete
                                                                                value={address}
                                                                                onChange={(e) => {
                                                                                    handleAddressChange(e), handleStateChangeForAddress(e)
                                                                                }}
                                                                                onSelect={handleAddressSelect}
                                                                            >
                                                                                {renderFuncForGooglePlaces}
                                                                            </PlacesAutocomplete>
                                                                            {addressError && <div style={{ marginTop: '10px', color: '#dc3545', fontsize: '0.875em' }}>{addressError}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {allAddress != '' || currentId ?
                                                                <div className="col-lg-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t("City")} </label>
                                                                                <div className="input-group">
                                                                                    <input name="city" className="form-control disabled-input" placeholder={t("Enter City")} type="text" maxLength={50}
                                                                                        disabled onChange={(e) => { setCity(e.target.value) }} value={address != "" ? city : ''} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t("State")} </label>
                                                                                <div className="input-group">
                                                                                    <input name="stateData" className="form-control disabled-input" placeholder={t("EnterState")} type="text" maxLength={50}
                                                                                        disabled onChange={(e) => { setState(e.target.value) }} value={state} />

                                                                                </div>
                                                                               
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t("Zip")} </label>
                                                                                <div className="input-group">


                                                                                    <Input name="postalCodeData" className="form-control disabled-input" placeholder={t("EnterPostalCode")}
                                                                                        disabled type="text" maxLength={12} value={postalCode}
                                                                                    />

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t("Country")} </label>
                                                                                <div className="input-group">
                                                                                    <input name="country" disabled className="form-control disabled-input" placeholder={t("EnterCountry")} type="text" maxLength={50} onChange={(e) => { setCountry(e.target.value) }} value={country} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t("Latitude")}</label>
                                                                                <div className="input-group">
                                                                                    <input name="country" className="form-control disabled-input" placeholder={t("EnterLatitude")}
                                                                                        disabled type="text" maxLength={50} onChange={(e) => { setLatitude(e.target.value) }} value={latitude} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{t("Longitude")} </label>
                                                                                <div className="input-group">
                                                                                    <input name="country" className="form-control disabled-input" placeholder={t("EnterLongitude")} type="text" maxLength={50}
                                                                                        disabled onChange={(e) => { setLongitude(e.target.value) }} value={longitude} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : ""}                                                         
                                                        </div>
                                                    </div>
                                                    {companyLogo.upload.pictures != "" || mediaFiles != null || currentId ?
                                                        <div className="company_logo w-100">
                                                            <ColorExtractor getColors={getColors}>
                                                                <img className="dis_image" src={companyLogo.upload.pictures[0] ? companyLogo.upload.pictures[0] : mediaFiles}></img>
                                                            </ColorExtractor>
                                                            <label className="form-label mb-0 d-inline-block mt-2"> <b>{t("ExtractedColors")} :</b></label>
                                                            <div className="colors_swatches">
                                                                {renderSwatches()}
                                                            </div>
                                                        </div> : ""
                                                    }

                                                    {companyLogo.upload.pictures != "" || mediaFiles != null || currentId ?
                                                        <div className="col-lg-12 grouping-div"
                                                        //onClick={togglePicker}
                                                        >
                                                            {companyLogo.upload.pictures != "" || mediaFiles != null || currentId ?
                                                                <div className="row mb-2">
                                                                    <div className="col-md-9 col-lg-9 col-xl-9">
                                                                        <div className="d-flex theme-option">
                                                                            <div className="d-flex align-items-center ">
                                                                                {" "}
                                                                                <input
                                                                                    className="mr-2"
                                                                                    type="radio"
                                                                                    value="show"
                                                                                    name="content"
                                                                                    id="show"
                                                                                    onClick={radioButtonValueChange}
                                                                                    checked={getRadioButton.selected === "show"}
                                                                                />
                                                                                <label className="m-0" for="show">{t("ThemefromExtractedColors")}</label>{" "}
                                                                            </div>

                                                                            <div className="d-flex align-items-center ml-4">
                                                                                {" "}
                                                                                <input

                                                                                    type="radio"
                                                                                    value="hide"
                                                                                    name="content"
                                                                                    id="hide"
                                                                                    className="mr-2"
                                                                                    onClick={radioButtonValueChange}
                                                                                    checked={getRadioButton.selected === "hide"}
                                                                                />
                                                                                <label className="m-0" for="show">{t("ThemefromColorPicker")}</label>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3 col-md-3 col-12 p-0">
                                                                        <div className="reset-btn-wrap text-right">
                                                                            {currentId == undefined || currentId == null ?
                                                                                <Link className="preview-btn btn btn-primary" onClick={() => handlePreview()}>
                                                                                    <span className="me-2"> <img src={PreviewIcon} alt="img" className="" /></span>
                                                                                    <span>{t("PreviewTheme")}</span>
                                                                                </Link> : ""
                                                                            }
                                                                            {currentId !== undefined && isReset ?
                                                                                <Link className="preview-btn btn btn-primary" onClick={() => handleReset()}>
                                                                                    <span className="me-2"> <img src={ResetColorIcon} alt="img" className="" /></span>
                                                                                    <span>{t("ResetTheme")}</span>
                                                                                </Link> : ""
                                                                            }
                                                                        </div>
                                                                    </div>                                                                   
                                                                </div>
                                                                : ""
                                                            }

                                                            {getRadioButton.show == "show" ?
                                                                <div className="col-lg-12 Ant-select-outer p-0">
                                                                    <div className="row">
                                                                        <div className="col-md-4 mt-4">
                                                                            <label>{t("HeaderColor")} </label>
                                                                            <Select className="form-select mb-0 flex-grow-1 pl" name="roles" aria-label=".form-select-lg example"
                                                                                // showSearch
                                                                                style={{ width: 300 }}
                                                                                placeholder={t("SelectHeaderColor")}

                                                                                key={headerId}
                                                                                id={headerId}
                                                                                defaultValue={headerId}
                                                                                value={headerId}
                                                                                onChange={(e) => {
                                                                                    setHeaderId(e),
                                                                                        setIsReset(true)
                                                                                }}
                                                                            >
                                                                                <Option value="0">{t('SelectHeaderColor')}</Option>
                                                                                {
                                                                                    colors && colors.map((colr, id) => (
                                                                                        <Option key={id} value={colr}>
                                                                                            <div className="d-flex gap20 align-items-center color-picker">
                                                                                                <div
                                                                                                    key={id}
                                                                                                    style={{
                                                                                                        backgroundColor: colr,
                                                                                                        width: 30,
                                                                                                        height: 30
                                                                                                    }}
                                                                                                />
                                                                                                {colr}
                                                                                            </div>
                                                                                        </Option>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </div>
                                                                        <div className="col-md-4 mt-4">

                                                                            <label>{t("FooterColor")} </label>
                                                                            <Select className="form-select mb-0 flex-grow-1" name="roles" aria-label=".form-select-lg example"
                                                                                style={{ width: 300 }}
                                                                                placeholder={t("SelectFooterColor")}
                                                                                defaultValue={footerId}
                                                                                key={footerId}
                                                                                onChange={(e) => {
                                                                                    setFooterId(e),
                                                                                     setIsReset(true)
                                                                                } }
                                                                            >
                                                                                <Option value="0">{t('SelectFooterColor')}</Option>
                                                                                {
                                                                                    colors && colors.map((colr, id) => (
                                                                                        <Option key={id} value={colr}>
                                                                                            <div className="d-flex gap20 align-items-center color-picker">
                                                                                                <div
                                                                                                    key={id}
                                                                                                    style={{
                                                                                                        backgroundColor: colr,
                                                                                                        width: 30,
                                                                                                        height: 30
                                                                                                    }}
                                                                                                />
                                                                                                {colr}
                                                                                            </div>
                                                                                        </Option>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </div>

                                                                        <div className="col-md-4 mt-4">
                                                                            <label>{t("BodyColor")} </label>
                                                                            <Select className="form-select mb-0 flex-grow-1" name="roles" aria-label=".form-select-lg example"
                                                                                style={{ width: 300 }}
                                                                                placeholder={t("SelectBodyColor")}
                                                                                defaultValue={bodyId}
                                                                                key={bodyId}
                                                                                onChange={(e) => {
                                                                                    setBodyId(e),
                                                                                     setIsReset(true)
                                                                                }}
                                                                            >
                                                                                <Option value="0">{t('SelectBodyColor')}</Option>
                                                                                {
                                                                                    colors && colors.map((colr, id) => (
                                                                                        <Option key={id} value={colr}>
                                                                                            <div className="d-flex gap20 align-items-center color-picker">
                                                                                                <div
                                                                                                    key={id}
                                                                                                    style={{
                                                                                                        backgroundColor: colr,
                                                                                                        width: 30,
                                                                                                        height: 30
                                                                                                    }}
                                                                                                />
                                                                                                {colr}
                                                                                            </div>
                                                                                        </Option>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </div>

                                                                        <div className="col-md-4 mt-4">
                                                                            <label>{t("IconColor")} </label>
                                                                            <Select className="form-select mb-0 flex-grow-1" name="roles" aria-label=".form-select-lg example"
                                                                                style={{ width: 300 }}
                                                                                placeholder={t("SelectIconColor")}
                                                                                defaultValue={iconId}
                                                                                key={iconId}
                                                                                onChange={(e) => {
                                                                                    setIconId(e),
                                                                                        setIsReset(true)
                                                                                }}
                                                                            >
                                                                                <Option value="0">{t('SelectIconColor')}</Option>
                                                                                {
                                                                                    colors && colors.map((colr, id) => (
                                                                                        <Option key={id} value={colr}>
                                                                                            <div className="d-flex gap20 align-items-center color-picker">
                                                                                                <div
                                                                                                    key={id}
                                                                                                    style={{
                                                                                                        backgroundColor: colr,
                                                                                                        width: 30,
                                                                                                        height: 30
                                                                                                    }}
                                                                                                />
                                                                                                {colr}
                                                                                            </div>
                                                                                        </Option>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                : ""
                                                            }
                                                            {getRadioButton.show == "hide" ?
                                                                <div>
                                                                    <div className="col-lg-12 Ant-select-outer"
                                                                    // onClick={togglePicker}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-md-4 mt-4">
                                                                                <div className="form-group color-theme"
                                                                                    onClick={toggleHeaderPicker}
                                                                                >
                                                                                    <label>{t("HeaderColor")}
                                                                                        <div className="color-btn" >
                                                                                            <img src={ColorPicker} onClick={() => OpenHeaderPicker()}></img>
                                                                                        </div>
                                                                                    </label>
                                                                                    <div className="input-group position-relative">
                                                                                        <Input name="color" className="form-control ps-5" placeholder={t("EnterHeaderColor")} type="text"

                                                                                            maxLength={50} value={headerId} onChange={(e) => {
                                                                                                setHeaderId(e.target.value),
                                                                                                setIsReset(true)

                                                                                            }}

                                                                                        />
                                                                                        <div className="position-absolute rounded" style={{
                                                                                            backgroundColor: headerId,
                                                                                            width: 84,
                                                                                            height: 30,
                                                                                            top: 6,
                                                                                            right: 20,

                                                                                        }}
                                                                                        />
                                                                                    </div>
                                                                                    {isOpen && !isFooter && !isIcon && !isBody ?
                                                                                        <div>
                                                                                            {showHeaderPicker && (
                                                                                                <ChromePicker
                                                                                                    color={headerId}
                                                                                                    onChangeComplete={handleChangeComplete}
                                                                                                />
                                                                                            )}
                                                                                        </div> : ""
                                                                                    }
                                                                                </div>


                                                                            </div>

                                                                            <div className="col-md-4 mt-4">
                                                                                <div className="form-group color-theme"
                                                                                    onClick={toggleFooterPicker}
                                                                                >
                                                                                    <label>{t("FooterColor")}
                                                                                        <div className="color-btn" >
                                                                                            <img src={ColorPicker} onClick={() => OpenFooterPicker()}></img>
                                                                                        </div>
                                                                                    </label>

                                                                                    <div className="input-group position-relative">
                                                                                        <Input name="color" className="form-control" placeholder={t("EnterFooterColor")}
                                                                                            type="text" maxLength={50} value={footerId} onChange={(e) => {
                                                                                                setFooterId(e.target.value),
                                                                                                setIsReset(true)
                                                                                            }}

                                                                                        />

                                                                                        <div className="position-absolute rounded" style={{
                                                                                            backgroundColor: footerId,
                                                                                            width: 84,
                                                                                            height: 30,
                                                                                            top: 6,
                                                                                            right: 20,

                                                                                        }}
                                                                                        />

                                                                                    </div>

                                                                                    {isFooter && !isOpen && !isIcon && !isBody ?
                                                                                        <div>
                                                                                            {showFooterPicker && (
                                                                                                <ChromePicker
                                                                                                    color={footerId}
                                                                                                    onChangeComplete={handleChangeFooter}
                                                                                                />
                                                                                            )}
                                                                                        </div> : ""
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4 mt-4">
                                                                                <div className="form-group"
                                                                                    onClick={toggleBodyPicker}
                                                                                >
                                                                                    <label>{t("BodyColor")}
                                                                                        <div className="color-btn" >
                                                                                            <img src={ColorPicker} onClick={() => OpenBodyPicker()}></img>
                                                                                        </div>
                                                                                    </label>

                                                                                    <div className="input-group position-relative">
                                                                                        <Input name="color" className="form-control" placeholder={t("EnterBodyColor")}
                                                                                            type="text" maxLength={50} value={bodyId} onChange={(e) => {
                                                                                                setBodyId(e.target.value),
                                                                                                setIsReset(true)
                                                                                            }}

                                                                                        />

                                                                                        <div className="position-absolute rounded" style={{
                                                                                            backgroundColor: bodyId,
                                                                                            width: 84,
                                                                                            height: 30,
                                                                                            top: 6,
                                                                                            right: 20,

                                                                                        }}
                                                                                        />

                                                                                    </div>

                                                                                    {isBody && !isOpen && !isFooter && !isIcon ?
                                                                                        <div>
                                                                                            {showBodyPicker && (
                                                                                                <ChromePicker
                                                                                                    color={bodyId}
                                                                                                    onChangeComplete={handleChangeBody}
                                                                                                />
                                                                                            )}
                                                                                        </div> : ""
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4 mt-4">
                                                                                <div className="form-group"
                                                                                    onClick={toggleIconPicker}
                                                                                >
                                                                                    <label>{t("IconColor")}
                                                                                        <div className="color-btn" >
                                                                                            <img src={ColorPicker} onClick={() => OpenIconPicker()} alt="color picker"></img>
                                                                                        </div>
                                                                                    </label>

                                                                                    <div className="input-group position-relative">
                                                                                        <Input name="color" className="form-control" placeholder={t("EnterIconColor")}
                                                                                            type="text" maxLength={50} value={iconId} onChange={(e) => {
                                                                                                setIconId(e.target.value),
                                                                                                    setIsReset(true)
                                                                                            }}

                                                                                        />

                                                                                        <div className="position-absolute rounded" style={{
                                                                                            backgroundColor: iconId,
                                                                                            width: 84,
                                                                                            height: 30,
                                                                                            top: 6,
                                                                                            right: 20,

                                                                                        }}
                                                                                        />

                                                                                    </div>

                                                                                    { isIcon && !isBody && !isOpen && !isFooter ?
                                                                                        <div>
                                                                                            {showIconPicker && (
                                                                                                <ChromePicker
                                                                                                    color={iconId}
                                                                                                    onChangeComplete={handleChangeIcon}
                                                                                                />
                                                                                            )}
                                                                                        </div> : ""
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                            }
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 my-2">
                                                        <div className="form-checkbox ">
                                                            {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                                                <label>
                                                                    <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> {t("Active")}</label> :
                                                                <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                                    {t("Active")}</label>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-primary" disabled={disable}>{pageButton}</button>
                                                        <Link className="btn btn-bordered ml-2" to="/Company">{t("Cancel")}</Link>
                                                    </div>

                                                </div>

                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
                                    <div class="preview-box">
                                        {
                                            isPreview || currentId?
                                                <div>
                                                    <div className="preview-theme">
                                                        <p> {t("PreviewTheme")}</p>
                                                    </div>
                                        <div className="preview-head" style={{
                                            backgroundColor: headerId,
                                        }}></div>
                                        <div className="preview-menubar text-center py-2" >
                                                        <img className="" src={currentId && mediaFiles != ""? mediaFiles : companyLogo.upload.pictures}/>
                                        </div>
                                        <div className="preview-body" style={{
                                            backgroundColor: bodyId,
                                        }}></div>
                                        <div className="preview-footer d-flex justify-content-between align-items-center px-2" style={{
                                            backgroundColor: footerId,
                                                    }}>
                                                        <p className="mb-0">© AuthenTEK (2024) | TEKaVerse ™</p>
                                                        <p className="mb-0">{t("Poweredby")} <img src={Previewlogo} alt="img"/></p>                                                    </div>
                                            </div>
                                                :
                                                <div className="preview-empty">
                                                    <p> {t("PreviewYourTheme")}</p>
                                                </div>                                        
                                        }
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </section>
                </Layout>
            }
        </div>
    )
}
export default connect()(AddCompany);
