import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import Layout from '../Layout';
import Loader from "../loader";
import { postallsites, updatesitetypedata } from '../../Redux/Actions/SiteActions';
import { getallcompanies } from '../../Redux/Actions/CompanyActions';
import { fetchSiteById, fetchAllCompaniesdata, fetchProjectDetails, fetchTimeZones, fetchAllSiteData, fetchTEKaControllerById } from "../../Redux/Api/fetch.api";
import { getallprojects,getallTimeZones } from '../../Redux/Actions/AccessibilityActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { Checkbox, Select } from "antd";
import { useTranslation } from "react-i18next";
const Option = Select.Option;

const AddTekacontroller = () => {
    const { t } = useTranslation('addTekaController');
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ipAddress, setIPAddress] = useState("");
    const [count, setCount] = useState(0);
    const [addCount, setAddCount] = useState(0);
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [isActive, setisActive] = useState(true)
    const [isCreateTable, setIsCreateTable] = useState(false)
    const [allSitesData, setAllSitesData] = useState([]);
    const [disable, setDisable] = useState(false);
    const dispatch = useDispatch(); 
    const [companyId, setCompanyId] = useState();
    const [ifUpdate, setifUpdate] = useState(false)
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    
    const [projects, setProjects] = useState([]);
    const [selectProjects, setSelectProjects] = useState([]);
    const [allAppData, setAllAppData] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [siteId, setSiteId] = useState();
    const [tekaId, setTEKaId] = useState();
    const [siteError, setSiteError] = useState(false);

   

   
    let history = useHistory();

    useEffect(() => {
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchTEKaControllerById(urlData[3]).then(
                response => {
                    
                    setDescription(response.data.data.description)
                    setSiteId(response.data.data.siteID)
                    setIsCreateTable(response.data.data.isCreateTable)
                    setTEKaId(response.data.data.teKaID)
                    setisActive(response.data.data.isActive)   
                    setifUpdate(true);
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    const handleChange = () => {
        debugger
        setisActive(!isActive)
    }
    const handleIsCreateTableChange = () => {
        setIsCreateTable(!isCreateTable)
    }

    useEffect(() => {
        getUserDetails();
        getAllSitesData();
    }, [])


    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {
              
                dispatch(getuserroleandmenu(response.data.data))
               
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
    let pageButton = t("Update")
    if (!currentId) {
        pageButton = t("Save")
    }
   

    const getAllSitesData = () => {
        setLoading(true)
        fetchAllSiteData()
            .then(response => {
                //dispatch(getallcompanies(response.data))
                setAllSitesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

   
    const addAllSites = async (params) => {       
        setLoading(true)
            const response = await axios.post(url.POST_TEKACONTROLLERS, {
                SiteID: siteId,
                Description: params.description.trim(),
                IsActive: isActive,
                isCreateTable: isCreateTable,
                
            }).then(response => {               
                //dispatch(postallsites(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("TEKaControllerAlreadyCreated"), {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(t("TEKaControllerCreatedsuccessfully"), {
                        className: "toastSuccess",
                    },);
                    history.push('/Tekacontroller');
                    setLoading(false);
                    setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
    }

    const updateSiteType = async (params) => {       
        setLoading(true)
        if (params.description) {
            const response = await axios.put(url.UPDATE_TEKACONTROLLER_DATA, {
                TEKaID: currentId,
                SiteID: siteId,
                Description: params.description.trim(),
                IsActive: isActive,
                isCreateTable: isCreateTable,

            }).then(response => {               
                //dispatch(updatesitetypedata(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("TEKaControllerAlreadyCreated"), {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(t("TEKaControllerupdatedsuccessfully"), {
                        className: "toastSuccess",
                    },);
                    history.push('/Tekacontroller');
                    setLoading(false);
                    setDisable(true);
                }
            })
                .catch((err) => { 
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: description ? description : '',
        },

        validationSchema: Yup.object({

             description: Yup.string().required("PleaseEnterDescription").matches(
                 "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                 "Please enter valid Description"
             )
        }),
        onSubmit: (values) => {   
            if (!siteError) {     
            { currentId == undefined || currentId == null || currentId == isNaN ? addAllSites(values) : updateSiteType(values) };   
        }
    }
    })


    return (
        <>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select mb-2">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/Tekacontroller">
                                        <img src={Arrow_leftbrown}></img>
                                        {t("Back")}
                                    </Link>
                                </h4>

                            </div>
                            {currentId ? 
                                <div>
                                    <label className="mb-0">{t("TEKaID")} : {tekaId}</label>
                                </div> : ""
                            }
                            <div className="content-area">
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        if (siteId == null || siteId == "Select Site") {
                                            setSiteError(true);
                                        }
                                        return false;
                                    }} >

                                    <div className="col-lg-12 wk-form-grup">
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("Site")} <span className="required-fields"> * </span></label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                        value={siteId}
                                                        key={siteId}

                                                        onChange={(e) => {
                                                            
                                                            setSiteId(e.target.value);
                                                            setSiteError(false);
                                                        }}
                                                    >
                                                        <option selected="">{t("SelectSite")}</option>
                                                        {
                                                            allSitesData && allSitesData.map(site =>
                                                                <option key={site.siteId} value={site.siteId}>
                                                                    {site.siteName} </option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                            < small className="text-danger d-block mt-2">{!siteError ? ' ' : t("PleaseSelectSite")}</small>
                                                        }

                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("Description")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="description" className="form-control" placeholder={t("EnterDescription")}
                                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setDescription(e.target.value) }}
                                                            onBlur={validation.handleBlur} value={validation.values.description}
                                                            invalid={validation.touched.description && validation.errors.description ? true : false}
                                                        />
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 mb-6">
                                                <div className="form-checkbox ">
                                                    <label>
                                                        <input type="checkbox" className="check mr-1" name="isCreateTable" checked={isCreateTable} value={isCreateTable} onChange={() => handleIsCreateTableChange()} ></input> {t("IsCreateTable")}
                                                    </label>
                                                </div>
                                                <div className="form-checkbox ">
                                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                                        <label>
                                                            <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> {t("Active")}</label> :

                                                        <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                            {t("Active")}</label>}
                                                </div>

                                            </div>  
                                            <div className="col-md-12 mb-8">
                                                <button type="submit" className="btn btn-primary" disabled={disable}>{pageButton}</button>
                                                <Link className="btn btn-bordered ml-2" to="/Tekacontroller">{t("Cancel")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
            
        </>
    )

}
export default connect()(AddTekacontroller);

