// #region Namespaces
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { Table } from "antd"; // Import the Table component from Ant Design
import Layout from '../Layout';
import '../../../src/custom.css';
import { getUserAccessLevels } from "../../Redux/Api/fetch.api";
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// #endregion

const AccessLevel = () => {

    // #region Global variables  
    const { userId } = useParams(); // Get the userId from the URL parameters
    const [userAccessLevels, setUserAccessLevels] = useState({}); // State to hold grouped data
    const [userDetails, setUserDetails] = useState({});
    // #endregion

    // #region useEffect / API calls
    useEffect(() => {
        // Fetch user access levels on component mount if userId is present
        if (userId) {
            GetUserAccessLevels(userId); // Pass userId dynamically
        }
    }, [userId]); // Dependency array with userId to re-fetch data if it changes
    // #endregion

    // #region Handle Functions
    // Get User Access Levels 
    const GetUserAccessLevels = async (userId) => {
        try {
            // Fetch events from API with dynamic userId
            const response = await getUserAccessLevels(userId);

            if (response?.data?.data) {
                // Convert API data to grouped format and update state
                // Flatten the structure by merging accessRights into each row
                const flattenedData = response.data.data.menuAccessView.map((item, index) => ({
                    key: index.toString(),
                    appName: item.appName,
                    menuTitle: item.menuTitle,
                    ...Object.fromEntries(
                        Object.entries(item.accessRights).map(([role, value]) => [
                            role,
                            <div className={`user-access-level-icon-circle ${value === "Tick" ? 'user-access-level-icon-tick' : 'user-access-level-icon-cross'}`}>
                                {value === "Tick" ? <DoneAllIcon /> : <CloseIcon />}
                            </div>
                        ])
                    )
                }));

                // Bind user Details 
                setUserDetails(response?.data?.data?.accessedUserDetails);

                // Group data by appName
                const groupedData = flattenedData.reduce((acc, curr) => {
                    if (!acc[curr.appName]) acc[curr.appName] = [];
                    acc[curr.appName].push(curr);
                    return acc;
                }, {});

                // Set the grouped data into state
                setUserAccessLevels(groupedData);
            }
        } catch (error) {
            console.error("Error fetching user access levels:", error); // Log any errors encountered
        }
    };

    // Column definitions for Ant Design Table
    const columns = [
        { title: 'Menu Title', dataIndex: 'menuTitle', key: 'menuTitle', align: 'center' },
        { title: 'Viewer', dataIndex: 'Viewer', key: 'Viewer', align: 'center' },
        { title: 'User', dataIndex: 'User', key: 'User' ,align: 'center' },
        { title: 'QA', dataIndex: 'QA', key: 'QA', align: 'center' },
        { title: 'Elevated 1', dataIndex: 'Elevated 1', key: 'Elevated1', align: 'center' },
        { title: 'Elevated 2', dataIndex: 'Elevated 2', key: 'Elevated2', align: 'center' },
        { title: 'Admin', dataIndex: 'Admin', key: 'Admin', align: 'center' },
        { title: 'Super Admin', dataIndex: 'SuperAdmin', key: 'SuperAdmin', align: 'center' },
        { title: 'Developer', dataIndex: 'Developer', key: 'Developer', align: 'center' },
        { title: 'Mechanic', dataIndex: 'Mechanic', key: 'Mechanic', align: 'center' },
    ];
    // #endregion

    return (
        <div>
            <Layout>
                <div className="p-3">
                    <section className="Company_section common common-table py-3">
                        <div className="container-fluid">
                            <div className="row">
                                {userDetails && userDetails.userName && userDetails.roleName && (
                                    <>
                                        <div className="col-md-4 support_log_head">
                                            <h4 className="m-0">User Access Level</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <h6 className="m-0 d-flex align-items-center justify-content-center user-access-level-userdetails">
                                                <AccountCircleIcon style={{ marginRight: '8px' }} />
                                                {userDetails.userName} ({userDetails.roleName})
                                            </h6>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            <p className="ml-md-auto mb-0"></p>                                           
                                        </div>
                                    </>
                                )}

                            </div>

                            <div className="container">
                                {/* Render a separate table for each App Name */}
                                {Object.keys(userAccessLevels).map((appName, index) => (
                                    <div key={index} className="mb-5">
                                        <h3 className="text-left">{appName}</h3>
                                        <div className="Viewer-table index--table position-relative support-row-tb common-table mb-4">
                                            <Table
                                                className='table-responsive antd--detail-table1 support assgin-table'
                                                columns={columns}
                                                dataSource={userAccessLevels[appName]} // Use grouped data for each appName
                                                pagination={false} // Set to true if you want pagination
                                                bordered
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </div>
    );
}

export default connect()(AccessLevel);
