import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from 'react-redux';
import Layout from "../Layout";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import '../../../src/calendar.css';
import {
    getAllSupportEvents, postSupportEvent, putSupportEvent, deleteSupportEvent, getAllAssignedTechnicians
    , getSubCompaniesByUserId
} from "../../Redux/Api/fetch.api";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Tooltip } from "antd";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CheckBox } from 'smart-webcomponents-react/checkbox';
import TechnicianList from "./SupportTechnicianList";
import ShareIcon from "../../assets/images/share.svg";
import TechnicianIcon from "../../assets/images/customer-support.png";
import { useTranslation } from "react-i18next";

const SupportCalendar = () => {

    // #region Global variables 
    const { t } = useTranslation('supportCalender');
    const schedulerRef = useRef();  // Reference for the Scheduler component
    const [events, setEvents] = useState([]);  // State to store the list of events
    const [technicians, setTechnicians] = useState([]);  // State to store the list of technicians (resources)
    const [resourceTechnicians, setResourceTechnicians] = useState([]);  // State to store the list of technicians (resources)
    const [searchedTechnicians, setSearchedTechnicians] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(false);  // State to manage the collapse of the navbar
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [statuses, setStatuses] = useState([]);

    const currentTimeIndicator = true;
    const shadeUntilCurrentTime = true;
    const headerDatePosition = 'far';
    const headerViewPositio = 'near';
    const scrollButtonsPosition = 'far';
    const groups = ['userId'];

    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison
    // #endregion

    // #region Handle TimeZone
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const timeZones = [
        {
            id: 'Central Standard Time',
            label: 'US/Chicago'
        },
        'UTC'
    ];
    // #endregion

    // #region useEffect Hooks
    // Fetch the list of events and resources (technicians) when the component is mounted
    useEffect(() => {
        getAllAssignedTechnician();
        initializeEventScheduler();  // Fetch data and initialize the scheduler 
    }, []);

    // Set up event listeners for drag and drop on the scheduler after it's rendered
    useEffect(() => {
        const schedulerElement = schedulerRef.current.nativeElement;

        // Add event listener to allow drag over events (to prevent default browser behavior)
        schedulerElement.addEventListener('dragover', (e) => e.preventDefault());

        // Add event listener to handle the drop event (for external drag-and-drop functionality)
        schedulerElement.addEventListener('drop', handleDrop);

        // Add event listeners for tooltips
        document.addEventListener('mouseover', handleMouseOver);
        document.addEventListener('mouseout', handleMouseOut);

        // Cleanup: remove event listeners when the component is unmounted
        return () => {
            schedulerElement.removeEventListener('dragover', (e) => e.preventDefault());
            schedulerElement.removeEventListener('drop', handleDrop);

            // Clean up event listeners on component unmount
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    // Dependencies: re-run the effect when these values change
    useEffect(() => {
        // This effect runs whenever the `selectedTechnicians` or `events` change.
        // It calls the `filterEvents` function to update the displayed events
        // based on the currently selected technicians.
        filterEvents();
    }, [selectedTechnicians, events]);

    // Dynamic template for header 
    useEffect(() => {
        const groupTemplate = document.createElement('template');
        groupTemplate.id = 'groupTemplate';
        groupTemplate.innerHTML = `
        <div className="support-custom-group-content">
            <img src="{{image}}"></img>
            <div className="support-details">
                <div className="name">{{label}}</div>
                <div className="speciality">Group : {{supportGroup}}</div>
                <div className="speciality">OrderId : {{orderId}}</div>
            </div>
        </div>`;

        document.body.appendChild(groupTemplate);
        schedulerRef.current.groupTemplate = groupTemplate.id;
    }, []);

    // #endregion

    // #region Functions
    const timelineHeaderFormatFunction = (date, orientation, isHeaderDetails, dateValue) => {
        if (orientation === 'horizontal') {
            const segments = dateValue.split(' ');

            if (segments.length === 2) {
                return `<div>${segments[1]}</div><div className="header-day-number">${segments[0]}</div>`;
            }
        }
        return dateValue;
    };

    const resources = [
        {
            label: 'Technician',
            value: 'userId',
            dataSource: resourceTechnicians
        }
    ];

    //const statuses = [{ "label": "Arvato", "value": "Arvato" }, { "label": "Authentek", "value": "Authentek" },
    //    { "label": "Berry Global Inc", "value": "Berry Global Inc" }];

    // Function to fetch event and technician data from the API and set the state
    const initializeEventScheduler = async () => {
        try {
            // Fetch events from the API
            const response = await getAllSupportEvents();

            // Convert the event times to the user's local time zone
            const eventsInLocalTime = response.data.data.map(event => ({
                ...event,
                // Ensure StartDate and EndDate are formatted in the user's local time
                StartDate: dayjs(event.StartDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'),
                EndDate: dayjs(event.EndDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss')
            }));

            // Initialize the scheduler with the converted event data
            schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                dataSource: eventsInLocalTime, // Use converted event times
                dataSourceType: 'array',
                dataFields: getSchedulerDataFields()
            });

            // Set the events in the local state
            setEvents(eventsInLocalTime);

            // Apply any custom calendar settings (if needed)
            await customCalendarSettings();
        } catch (error) {
            // Handle any errors that occur during the event fetching process
            console.error("Error fetching support events:", error);
        }
    };




    // Custom Calendar Settings
    const customCalendarSettings = () => {
        const scheduler = document.querySelector('smart-scheduler');
        //let hasFetchedSubCompanies = false; // Flag to track if the data has been fetched
        //let subCompanies = []; // Store sub-companies here

        scheduler.addEventListener('editDialogOpen', async function (event) {
            const editors = event.detail.editors;

            if (!editors) {
                return;
            }

            // get the sub companies based on user id
            //// Fetch sub-companies only once
            //if (!hasFetchedSubCompanies) {
            //    try {
            //        subCompanies = await getAllSuCompaniesByUserId(event.detail.item.userId);
            //        setStatuses(subCompanies); // Assuming setStatuses is defined
            //        hasFetchedSubCompanies = true; // Set the flag to true after fetching
            //    } catch (error) {
            //        console.error("Error fetching sub-companies:", error);
            //        return; // Exit if there's an error
            //    }
            //}

            //event.detail.item.status = subCompanies[0].label;

            const schedulerEvent = event.detail.item,
                allDayEditor = editors.allDay,
                repeatEditor = editors.repeat,
                statusEditor = editors.status,
                labelEditor = editors.label,
                descriptionEditor = editors.description,
                notificationEditor = editors.notifications,
                disableLabel = editors.label,
                disableStartDate = editors.dateStart,
                disableEndDate = editors.dateEnd,
                disableBackgroundColor = editors.backgroundColor,
                resourceDataEditor = editors.resourceData,
                resourcesEditor = editors.resources;

            // Hidden all properties 
            repeatEditor.classList.add('smart-hidden');
            allDayEditor.classList.add('smart-hidden');
            //statusEditor.classList.add('smart-hidden');
            notificationEditor.classList.add('smart-hidden');
            resourceDataEditor.classList.add('smart-hidden');
            resourcesEditor.classList.add('smart-hidden');

            labelEditor.querySelector('.smart-element').placeholder = 'Enter Support Note...';
            descriptionEditor.querySelector('.smart-element').placeholder = 'Enter Support Description...';
            statusEditor.querySelector('#schedulerstatusLabel').textContent = "Sub Companies"


            // Get today's date without time for comparison
            const today_ = new Date();
            today_.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(schedulerEvent.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Check if the start date is before today or if the event falls within restricted hours
            if (startDate < today_) {
                // Hide Ok footer 
                event.detail.target.footerPosition = 'none';
                disableLabel.classList.add('smart-disabled');
                descriptionEditor.classList.add('smart-disabled');
                disableStartDate.classList.add('smart-disabled');
                disableEndDate.classList.add('smart-disabled');
                disableBackgroundColor.classList.add('smart-disabled');
            } else {
                // Show Ok footer 
                event.detail.target.footerPosition = 'bottom';
                disableLabel.classList.remove('smart-disabled');
                descriptionEditor.classList.remove('smart-disabled');
                disableStartDate.classList.remove('smart-disabled');
                disableEndDate.classList.remove('smart-disabled');
                disableBackgroundColor.classList.remove('smart-disabled');
            }
        });
    }

    // Get all assinged technicians
    const getAllAssignedTechnician = async () => {
        try {
            const response = await getAllAssignedTechnicians();  // Fetch events from API

            // Map the unique clients as resources for the scheduler (technicians)
            const technicians_ = response.data.data.map(resource => ({
                label: resource.userName,  // Display name for the technician
                id: resource.clientId,   // Unique ID for the technician,
                supportGroup: resource.supportGroup,
                orderId: resource.orderId,
                image: TechnicianIcon
            }));

            setTechnicians(technicians_);
            setResourceTechnicians(technicians_);

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    const [currentUserId, setCurrentUserId] = useState(null);
    const handleItemClick = async (event) => {
        const userId = event.detail.itemObj?.userId;

        if (userId) {
            // Fetch sub-companies for the current user ID
            const subCompanies = await getAllSuCompaniesByUserId(userId);
            setStatuses(subCompanies); // Set the new statuses
        }
    };

    // Get all assinged technicians
    const getAllSuCompaniesByUserId = async (userId) => {
        try {
            const response = await getSubCompaniesByUserId(userId);  // Fetch companies from API

            // Map the unique clients as resources for the scheduler (technicians)
            const companies = response.data.data.map(companyName => ({
                label: companyName,
                value: companyName
            }));

            return companies; // Return the companies array

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
            return []; // Return an empty array on error
        }
    };

    // Define data fields for the scheduler
    function getSchedulerDataFields() {
        return [
            { name: 'id', map: 'eventId', dataType: 'number' },
            { name: 'label', map: 'label', dataType: 'string' },
            { name: 'dateStart', map: 'startDate', dataType: 'date' }, // Change to 'date'
            { name: 'dateEnd', map: 'endDate', dataType: 'date' },     // Change to 'date'
            { name: 'description', map: 'description', dataType: 'string' },
            { name: 'allDay', map: 'isAllDay', dataType: 'bool' },
            { name: 'backgroundColor', map: 'backGroundColor', dataType: 'string' },
            { name: 'isDrag', map: 'isDrag', dataType: 'bool' },
            { name: 'userId', map: 'clientId', dataType: 'number' },
            { name: 'orderId', map: 'orderId', dataType: 'number' },
            { name: 'groupName', map: 'supportGroup', dataType: 'string' },
            { name: 'conference', map: 'conference', dataType: 'string' },
            { name: 'status', map: 'status', dataType: 'string' }
        ];
    }

    // Function to handle the drag start event for external tasks (technicians)
    const handleDragStart = (event, label, bgColor, userId, supportGroup) => {
        const data = JSON.stringify({ label, bgColor, userId, supportGroup });  // Serialize data as JSON
        event.dataTransfer.setData('application/json', data);  // Set the data in JSON format
    };

    // Function to handle the drop event when a task (technician) is dropped on the scheduler
    const handleDrop = (event) => {
        const data = event.dataTransfer.getData('application/json');  // Retrieve the label of the dropped task
        const { label, bgColor, userId, supportGroup } = JSON.parse(data);
        const scheduler = schedulerRef.current;

        // Get the userId from the drop target
        const userIdFromTarget = event.target.getAttribute('userid');
        if (userIdFromTarget == userId) {

            if (scheduler) {
                // Get the drop location date/time based on the coordinates of the drop event
                const dropDate = scheduler.getDateFromCoordinates(event.clientX, event.clientY);
                const startDate = new Date(dropDate);
                startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

                // Validation checking 
                if (startDate >= today) {
                    const newEvent = {
                        label: label,  // Set the label of the event (technician's name)
                        dateStart: dropDate,  // Start time of the new event
                        dateEnd: new Date(dropDate.getTime() + 3600000), // Set duration to 1 hour
                        backgroundColor: bgColor,  // Background color for the event
                        userName: label,
                        userId: userId,
                        description: ""
                    };

                    // Add the new event to the existing list of events
                    setEvents((prevEvents) => {
                        const updatedEvents = [...prevEvents, newEvent];
                        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                            dataSource: updatedEvents,
                            dataSourceType: 'array',
                            dataFields: getSchedulerDataFields()
                        });

                        // Insert data into the databse on drag and drop
                        refreshData('insert', newEvent);
                        return updatedEvents;
                    });
                }
                else {
                    toast.error(t("Pleasenoteupdates"), {
                        className: "toastError",
                    });
                }
            }
        }
        else {
            toast.error(t("Pleasenotethattechnicians"), {
                className: "toastError",
            });
        }
    };

    // Function to toggle the collapse of the navbar
    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);  // Toggle the collapse state
    };

    // Refresh data after CRUD operations
    // const refreshData = async (action, eventItem) => {
    //     // Prepare the payload with the exact date/time as selected by the user
    //     const payload = {
    //         EventId: eventItem.id,
    //         EventName: eventItem.label,
    //         // Send date as it is without any conversion
    //         StartDate: dayjs(eventItem.dateStart).format('YYYY-MM-DDTHH:mm:ss'),
    //         EndDate: dayjs(eventItem.dateEnd).format('YYYY-MM-DDTHH:mm:ss'),
    //         Description: eventItem.description,
    //         IsAllDay: eventItem.allDay,
    //         SupportUserGroupId: eventItem.userId,
    //         BackGroundColor: eventItem.backgroundColor,
    //         Conference: eventItem.conference
    //     };

    //     try {
    //         let response;
    //         switch (action) {
    //             case 'insert':
    //                 response = await postSupportEvent(payload); // Handle Insert

    //                 break;
    //             case 'update':
    //                 response = await putSupportEvent(payload); // Handle Update
    //                 break;
    //             case 'delete':
    //                 response = await deleteSupportEvent(eventItem.id); // Handle Delete
    //                 break;
    //             default:
    //                 throw new Error("Unknown action type");
    //         }

    //         const message = response?.data?.message || "Operation successful";
    //         toast.success(message, {
    //             className: "toastSuccess",
    //         });

    //         await initializeEventScheduler(); // Refresh event data
    //     } catch (err) {
    //         console.error("Error:", err);
    //         toast.error("An error occurred while processing the request.", {
    //             className: "toastError",
    //         });
    //     }
    // };

    const refreshData = async (action, eventItem) => {
        let response;
        let successMessage;
        let errorMessage;
        // Prepare the payload with the exact date/time as selected by the user
        const payload = {
            EventId: eventItem.id,
            EventName: eventItem.label,
            // Send date as it is without any conversion
            StartDate: dayjs(eventItem.dateStart).format('YYYY-MM-DDTHH:mm:ss'),
            EndDate: dayjs(eventItem.dateEnd).format('YYYY-MM-DDTHH:mm:ss'),
            Description: eventItem.description,
            IsAllDay: eventItem.allDay,
            SupportUserGroupId: eventItem.userId,
            BackGroundColor: eventItem.backgroundColor,
            Conference: eventItem.conference
        };

        try {
            let response;
            switch (action) {
                case 'insert':
                    response = await postSupportEvent(payload); // Handle Insert
                    successMessage = t("Eventcreatedsuccessfully")
                    errorMessage = t("Noeventsadded")
                    break;
                case 'update':
                    response = await putSupportEvent(payload); // Handle Update
                    successMessage = t("Eventupdatedsuccessfully")
                    errorMessage = t("Eventnotfoundorcouldnotbeupdated")
                    break;
                case 'delete':
                    response = await deleteSupportEvent(eventItem.id); // Handle Delete
                    successMessage = t("Eventdeletedsuccessfully")
                    errorMessage = t("Eventnotfound.")
                    break;
                default:
                    throw new Error("Unknown action type");
            }
            if (response.data.success) {
                toast.success(successMessage, {
                    className: "toastSuccess",
                });
            } else {
                toast.error(errorMessage, {
                    className: "toastError",
                });
            }

            //  const message = response?.data?.message || "Operation successful";           
            await initializeEventScheduler(); // Refresh event data
    } catch (err) {
        console.error("Error:", err);
        toast.error("An error occurred while processing the request.", {
            className: "toastError",
        });
    }
};



// handle Scheduler Update
const handleSchedulerUpdate = async (detail, isDrag = false) => {
    debugger;

    const { item, itemDateRange } = detail;
    if (itemDateRange.dateStart && itemDateRange.dateEnd) {
        // Get the start date from the scheduler event and remove the time component
        const startDate = new Date(itemDateRange.dateStart);
        startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

        // Validation checking 
        if (itemDateRange.dateStart >= today) {
            item.dateStart = itemDateRange.dateStart;
            item.dateEnd = itemDateRange.dateEnd;
            item.isDrag = isDrag;
            refreshData('update', item);
        }
        else {
            toast.error(t("Pleasenote"), {
                className: "toastError",
            });
            // Re-initialize the event scheduler after each successful action
            await initializeEventScheduler();
        }
    }
}

// Check if the mouse is over an event element
const handleMouseOver = (event) => {
    // Check if the mouse is over an event element
    if (event.target.classList.contains('smart-scheduler-event-content')) {
        const content = event.target;
        const label = content.querySelector('.support-technician-name')?.textContent || 'No label';
        const time = content.querySelector('.support-small-time')?.textContent || 'No time';
        const desc = content.querySelector('.desc')?.textContent || 'No desc';
        const groupName = content.querySelector('.support-GroupName')?.textContent || 'No group';

        const tooltip = document.createElement('div');
        tooltip.className = 'event-tooltip';
        tooltip.innerHTML = `
            Technician : ${label}<br>
            Assigned Time : ${time}<br>
            ${desc}<br>            ${groupName}
        `; // Use <br> for line breaks

        document.body.appendChild(tooltip);

        // Position the tooltip
        const rect = content.getBoundingClientRect();
        tooltip.style.left = `${rect.left}px`;
        tooltip.style.top = `${rect.bottom + 5}px`; // Position below the event
        tooltip.classList.add('visible');
    }
};

// Remove tooltips when the mouse leaves the event area
const handleMouseOut = (event) => {
    // Remove tooltips when mouse leaves the event area
    if (event.target.classList.contains('smart-scheduler-event-content')) {
        const tooltips = document.querySelectorAll('.event-tooltip');
        tooltips.forEach(tooltip => tooltip.remove());
    }
};

// Handled technician selections
const handleTechnicianSelection = (id) => {
    const technician = technicians.find(t => t.id === id);

    setSelectedTechnicians(prev => {
        const updatedSelected = prev.some(t => t.id === id)
            ? prev.filter(t => t.id !== id) // If technician is already selected, remove it
            : [...prev, technician]; // If not selected, add it

        // Update resource technicians with the new selected technicians
        if (updatedSelected && updatedSelected.length > 0) {
            setResourceTechnicians(updatedSelected);
        }
        else {
            setResourceTechnicians(technicians);
        }

        return updatedSelected; // Return the updated state
    });
};

// Filter technicians
const filterEvents = () => {
    const filteredEvents = events.filter(event =>
        selectedTechnicians.length === 0 || selectedTechnicians.some(selected => selected.id === event.clientId)
    );

    schedulerRef.current.dataSource = new window.Smart.DataAdapter({
        dataSource: filteredEvents,
        dataSourceType: 'array',
        dataFields: getSchedulerDataFields()
    });
};
// #endregion

// #region Handle Events
// Event handlers for Scheduler
const handleDragEnd = (event) => handleSchedulerUpdate(event.detail, true);
const handleResizeEnd = (event) => handleSchedulerUpdate(event.detail);
const handleItemUpdate = (event) => refreshData('update', event.detail.item);
const handleItemRemove = (event) => refreshData('delete', event.detail.item);
const handleItemInsert = (event) => refreshData('insert', event.detail.item);

// Apply event template for each events
const eventTemplate = function (eventContent, eventObj) {
    const scheduler = this;

    let eventContentWrapper = eventContent.querySelector('.event-content-wrapper');

    if (!eventContentWrapper) {
        eventContentWrapper = document.createElement('div');
        eventContentWrapper.classList.add('event-content-wrapper');
    }

    let label = eventContent.querySelector('label'),
        time = eventContent.querySelector('div.time'),
        orderNoContainer = eventContent.querySelector('.support-order-no-container'),
        descLabel = eventContent.querySelector('.desc'),
        orderIdCircle = eventContent.querySelector('.support-Orderid-circle'),
        groupName = eventContent.querySelector('.support-GroupName'),
        img = eventContent.querySelector('img'),
        inviteButton = eventContent.querySelector('.invite-link-button'); // Check for existing button

    // Existing label for the event
    if (!label) {
        label = document.createElement('label');
        label.classList.add('time', 'support-technician-name');
        eventContentWrapper.appendChild(label);
    }

    // Time label
    if (!time) {
        time = document.createElement('div');
        time.classList.add('time', 'support-small-time');
        eventContentWrapper.appendChild(time);
    }

    // Description label
    if (!descLabel) {
        descLabel = document.createElement('div');
        descLabel.classList.add('desc', 'support-small-time');
        eventContentWrapper.appendChild(descLabel);
    }

    // Group Name
    if (!groupName) {
        groupName = document.createElement('div');
        groupName.classList.add('support-GroupName');
        eventContentWrapper.appendChild(groupName);
    }

    // Order No container for aligning Order ID and Circle
    if (!orderNoContainer) {
        orderNoContainer = document.createElement('div');
        orderNoContainer.classList.add('support-order-no-container');
        eventContentWrapper.appendChild(orderNoContainer);
    }

    // Order ID circle
    if (!orderIdCircle) {
        orderIdCircle = document.createElement('div');
        orderIdCircle.classList.add('support-Orderid-circle');
        orderNoContainer.appendChild(orderIdCircle);
    }

    // Set text content
    label.textContent = eventObj.label;

    let startFrom = new Intl.DateTimeFormat(scheduler.locale, {
        hour: scheduler.hourFormat,
        minute: scheduler.minuteFormat
    }).format(eventObj.dateStart);

    let endTo = new Intl.DateTimeFormat(scheduler.locale, {
        hour: scheduler.hourFormat,
        minute: scheduler.minuteFormat
    }).format(eventObj.dateEnd);

    time.textContent = `${startFrom} - ${endTo}`;

    // Set the Order ID circle color and text
    orderIdCircle.style.backgroundColor = eventObj.orderIdColor || '#FF5722'; // Default color
    orderIdCircle.textContent = eventObj.orderId;

    // Check if "Order Id:" label already exists
    let orderIdLabel = orderNoContainer.querySelector('.support-order-id-label');
    if (!orderIdLabel) {
        orderIdLabel = document.createElement('span');
        orderIdLabel.classList.add('support-order-id-label');
        orderIdLabel.textContent = "Order Id : ";
        orderNoContainer.insertBefore(orderIdLabel, orderIdCircle); // Insert before the circle
    }

    // Set additional information
    descLabel.textContent = "Description : " + (eventObj.description || "N/A");
    groupName.textContent = "Group : " + (eventObj.groupName || "N/A");

    // Image handling
    if (eventObj.img) {
        if (!img) {
            img = document.createElement('img');
            eventContentWrapper.appendChild(img);
        }
        img.src = eventObj.img;
    } else if (img) {
        img.remove();
    }

    // Invite button
    if (!inviteButton) {
        //inviteButton = document.createElement('button');
        //inviteButton.classList.add('invite-link-button', 'ant-btn', 'ant-btn-primary'); // Ant Design classes

        //// Create image element
        //const image = document.createElement('img');
        //image.src = ShareIcon; // Use the provided image
        //image.alt = 'Invite via link'; // Alt text for accessibility
        //image.style.width = '20px'; // Adjust width if needed
        //image.style.marginRight = '5px'; // Space between image and text

        //inviteButton.appendChild(image);
        //inviteButton.appendChild(document.createTextNode(' Send Email'));

        //inviteButton.onclick = () => {
        //    const inviteLink = eventObj.inviteLink || '#'; // Use the invite link from eventObj
        //    window.open(inviteLink, '_blank'); // Open link in a new tab
        //};

        //eventContentWrapper.appendChild(inviteButton);
    }

    if (!eventContentWrapper.parentElement) {
        eventContent.appendChild(eventContentWrapper);
    }

    return eventContent;
}
// #endregion

// #region User Interface
return (
    <div>
        <>
            <Layout>
                <div style={styles.supportcontainer}>
                    <Toaster />

                    {/* Collapsible navbar */}
                    <div style={{ ...styles.navbar, width: isCollapsed ? '80px' : '22%' }}>
                        <div style={styles.header}>
                            {!isCollapsed && <h4>{t("SupportTechnicians")}</h4>}

                            <Tooltip title={isCollapsed ? t("OpenMenu") : t("Close Menu")}>
                                <button onClick={toggleCollapse} className="support-technicians-toggleButton">
                                    {isCollapsed ? <MenuOpenIcon fontSize="small" /> : <MenuIcon fontSize="small" />}
                                </button>
                            </Tooltip>
                        </div>

                        {!isCollapsed && (
                            <div style={{ overflowY: 'auto', maxHeight: '600px' }}> {/* Scrollable Container */}
                                <TechnicianList
                                    technicians={technicians}
                                    loading={false}
                                    selectedTechnicians={selectedTechnicians}
                                    handleTechnicianSelection={handleTechnicianSelection}
                                    handleDragStart={handleDragStart}
                                />
                            </div>
                        )}
                    </div>


                    {/* Scheduler component on the right side */}
                    <div style={styles.scheduler}>
                        <Scheduler
                            ref={schedulerRef}  // Reference to the Scheduler component
                            view="week"  // Set the view to 'week'
                            id="scheduler"
                            onDragEnd={handleDragEnd}
                            onResizeEnd={handleResizeEnd}
                            onItemUpdate={handleItemUpdate}
                            onItemRemove={handleItemRemove}
                            onItemInsert={handleItemInsert}
                            currentTimeIndicator={currentTimeIndicator}
                            //shadeUntilCurrentTime={shadeUntilCurrentTime}
                            //headerDatePosition={headerDatePosition}
                            //headerViewPositio={headerViewPositio}
                            scrollButtonsPosition={scrollButtonsPosition}
                            eventTemplate={eventTemplate}
                            groups={groups}
                            timelineHeaderFormatFunction={timelineHeaderFormatFunction}
                            resources={resources}
                            //statuses={[{ "label": "None" }, { "label": "Free", "value": "free" }, { "label": "Tentative", "value": "tentative" }]}
                            statuses={statuses.length ? statuses : [{ label: "Loading...", value: "loading" }]}
                            onItemClick={handleItemClick}
                        //timeZone={'US Eastern Standard Time'}
                        />


                    </div>
                </div>
            </Layout>
        </>
    </div>
);
    // #endregion
};

// #region Styles 
const styles = {
    supportcontainer: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '10px',
    },
    navbar: {
        transition: 'width 0.3s ease',  // Smooth transition for collapsing
        padding: '20px',  // Padding inside the navbar
        borderRight: '2px solid #ccc',  // Border separating the navbar and scheduler
        overflow: 'hidden',  // Hide overflow content when collapsed
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 0 0 10px', // Rounded corners on the left side
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',  // Align items to the start and end
        marginBottom: '15px',  // Add space below the header
    },
    scheduler: {
        width: 'calc(100% - 80px)',  // Adjust width based on collapsed navbar width (80px when collapsed)
        padding: '20px',  // Add padding around the scheduler
        borderRadius: '0 10px 10px 0', // Rounded corners on the right side
    }
};
// #endregion

export default connect()(SupportCalendar);
