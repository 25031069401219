import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import Loader from "../loader";
import Add from "../../assets/images/Add.svg";
import { fetchAllBannerdata, fetchProjectDetails, fetchBannerById, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getallbanners, saveallbanners, updatebannerdata } from '../../Redux/Actions/BannerActions';
import Edit_light from "../../assets/images/Edit_light.svg";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Tooltip } from 'antd';
import { Modal, Button } from "react-bootstrap";
import { getallprojects } from "../../Redux/Actions/AccessibilityActions";
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Form, FormFeedback } from "reactstrap"
import ColorPicker from "../../assets/images/colorpicker.png";
import { ChromePicker } from 'react-color';
import axios from "axios";
import * as url from "../../api/uri_helper";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";

const BannerIndex = () => {
    const { t} = useTranslation('banner');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    const loggedUserId = localStorage.getItem('UserId')  
    const [currentId, setCurrentId] = useState();
    const [isActive, setisActive] = useState(true);
    let history = useHistory();
    const [bannerModal, setBannerModal] = useState(false);
    const [allProjects, setAllProjects] = useState([])
    const [projectError, setProjectError] = useState(false)
    const [appId, setAppId] = useState(0);
    const [description, setDescription] = useState("");
    const [count, setCount] = useState(0);
    const [forecolorId, setForeColorId] = useState("");
    const [backcolorId, setBackColorId] = useState("");
    const [isForeColor, setIsForeColor] = useState(false)
    const [isBackColor, setIsBackColor] = useState(false)
    const [showPicker, setShowPicker] = useState(false);
    const [showForePicker, setShowForePicker] = useState(false);
    const [showBackPicker, setShowBackPicker] = useState(false);
    const [disable, setDisable] = useState(false);
    const [checked, setChecked] = useState(true);
    const [roleName, setRoleName] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getRoleDetails();
        getBannerDetails();
        getallapps();
    }, []);

    let pageButton = t("Update")
    if (!currentId) {
        pageButton = t("Save")
    }

    const handleActiveChange = () => {

        setisActive(!isActive)

    }

    useEffect(() => {
        if (currentId) {
            setLoading(true)
            fetchBannerById(currentId).then(
                response => {
                    setAppId(response.data.data.appId);
                    setDescription(response.data.data.description)
                    setForeColorId(response.data.data.foreColor)
                    setBackColorId(response.data.data.backColor)
                    setisActive(response.data.data.isActive)
                    // setifUpdate(true);
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }, [currentId])

    const getallapps = () => {
        setLoading(true)
        fetchProjectDetails()
            .then(response => {
                dispatch(getallprojects(response.data))
                setAllProjects(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    const handleEdit = (bannerId) => {
        setCurrentId(bannerId)
        setBannerModal(true)
    }

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))

                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }


    const getBannerDetails = () => {
        setLoading(true)
        fetchAllBannerdata()
            .then(response => {

                dispatch(getallbanners(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const addAllBanners = async (params) => {
        setLoading(true)
        if (params.description) {
            const response = await axios.post(url.POST_BANNERS, {
                AppId: appId,
                Description: params.description.trim(),
                ForeColorCode: forecolorId,
                BackColorCode: backcolorId,
                IsActive: isActive,

            }).then(response => {
                setLoading(true)
                dispatch(saveallbanners(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("Banneralreadycreated"), {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    // setDisable(false)
                }
                else {
                    toast.success(t("Bannercreatedsuccessfully"), {
                        className: "toastSuccess",
                    },);
                    setBannerModal(false)
                    setAppId("")
                    setDescription("")
                    setForeColorId("")
                    setBackColorId("")
                    getBannerDetails();

                    setLoading(false);
                    // setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    }

    const updateBannerData = async (params) => {
        setLoading(true)
        if (params.description) {
            const response = await axios.put(url.UPDATE_BANNER_DATA, {
                BannerId: currentId,
                AppId: appId,
                Description: params.description.trim(),
                ForeColorCode: forecolorId,
                BackColorCode: backcolorId,
                IsActive: isActive,

            }).then(response => {
                dispatch(updatebannerdata(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("Projectalreadyadded"), {
                        className: "toastSuccess",
                    },);
                    setBannerModal(false);
                    setAppId("")
                    setDescription("")
                    setForeColorId("")
                    setBackColorId("")
                    setCurrentId()
                    setLoading(false);
                    //setDisable(false)
                }
                else {
                    toast.success(t("Bannersuccessfullyupdated"), {
                        className: "toastSuccess",
                    },);

                    // setLoading(false);
                    setBannerModal(false)
                    setAppId("")
                    setDescription("")
                    setForeColorId("")
                    setBackColorId("")
                    setCurrentId()
                    getBannerDetails();
                    // setDisable(true);
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };

    const handleBannerAdd = () => {
        setBannerModal(true);

    };

    const handleChangeComplete = (newColor) => {
        setForeColorId(newColor.hex);
        setShowForePicker(false);
        setIsForeColor(false);
    };

    const handleChangeBackColor = (newColor) => {
        setBackColorId(newColor.hex);
        setShowBackPicker(false);
        setIsBackColor(false)
    };

    const toggleForePicker = () => {
        setShowForePicker(!showForePicker);
    };

    const toggleBackPicker = () => {
        setShowBackPicker(!showBackPicker);
    };

    const handleClose = () => {
        setLoading(true)
        setBannerModal(false);
        setAppId("")
        setDescription("")
        setForeColorId("")
        setBackColorId("")
        setCurrentId()
        setShowForePicker(false);
        setShowBackPicker(false);
        getBannerDetails();
        setLoading(false)

    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const handleBannerClose = () => {
        setBannerModal(false);
    };

    const OpenForeColorPicker = () => {
        setIsForeColor(!isForeColor)
        setIsBackColor(false)

    };

    const OpenBackColorPicker = () => {
        setIsForeColor(false)
        setIsBackColor(!isBackColor)
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: description ? description : '',
        },

        validationSchema: Yup.object({
            description: Yup.string().required(t("PleaseEnterDescription")).matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Description"
            ),
        }),
        onSubmit: (values) => {

            if (!projectError) {

                { currentId == undefined || currentId == null || currentId == isNaN ? addAllBanners(values) : updateBannerData(values) };
            }
        }
    })

    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },

        {
            title: t("ProjectName"),
            dataIndex: "appName",
        },

        {
            title: t("Description"),
            dataIndex: "description",
            className: "description_projects",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },

        {
            title: t("ForeColor"),
            dataIndex: "foreColor",
        },

        {
            title: t("BackColor"),
            dataIndex: "backColor",
        },

        {
            title: t("Status"),
            dataIndex: "isActive",
            render: (data) => (data ? <p> Active</p> : <p>Inactive </p>),
        },

        {
            title: t("Action"),
            dataIndex: "",
            render: (data, companyId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() =>
                    handleEdit(data.bannerId)

                }
                ></img>
            </button>
            </>)
        },
    ]

    return (
        <div>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    <Layout>
                        <section className="Company_section common ">
                            <Toaster />
                            {loading ? <Loader /> : ''}
                            <div className="container banner-container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="m-0">{t("Banners")}</h4>
                                    <div className="ml-auto">
                                        <button className="btn btn-primary d-flex align-items-center btn-new" onClick={() =>
                                            handleBannerAdd()
                                        }><img src={Add}></img>{t("AddBanner")}</button>
                                    </div>

                                </div>

                                <div className="Viewer-table index--table position-relative common-table mb-4">

                                    <Table
                                        className='table-responsive antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                                        onChange={handleChange}
                                        // scroll={{ x: "450px" }}
                                        paginationTotalRows={total}

                                    />
                                </div>

                            </div>

                        </section>

                    </Layout>

                    <Modal
                        show={bannerModal}
                        onHide={handleBannerClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-dialog-video modal-dialog modal-dialog-centered"
                    >
                        <Modal.Header closeButton><h4 className="text-black">{currentId ? t("UpdateBanner") : t("AddBanner")}</h4>
                            {/* <Modal.Title>Add Banner</Modal.Title>*/}
                        </Modal.Header>
                        <Modal.Body>
                            {
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        if (appId == 0 || appId == "Select project") {
                                            setProjectError(true);

                                        }
                                    }}
                                >
                                    <div className="add-banner-body">
                                        <div className="row">
                                            <div className="col-lg-6 wk-form-grup">
                                                <div className="states-drop mr-2">
                                                    <label className="text-black mr-2 mb-0 d-block">{t("Apps")}<span className="required-fields"> * </span></label>
                                                    <select className="form-select w-100" value={appId}
                                                        key={appId}
                                                        onChange={(e) => {
                                                            setAppId(e.target.value)
                                                            if (e.target.value === "0" || e.target.value == "Select Project") {
                                                                setProjectError(true)
                                                            }
                                                            else {
                                                                setProjectError(false)

                                                            }

                                                        }}

                                                    >
                                                        <option selected value="0">{t("SelectProject")}</option>
                                                        {allProjects.map(item =>

                                                            <option value={item.appId}>{item.name}
                                                            </option>
                                                        )}
                                                    </select>
                                                </div>
                                                {< small className="text-danger d-block mt-1">{!projectError ? ' ' : t("PleaseSelectProject")}</small>}

                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0">{t("Description")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="description" className="form-control  custom-form-control" placeholder={t("EnterDescriptionHere")}
                                                            type="text"
                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                                setDescription(e.target.value.replace(/\s+/g, ' '))
                                                            }}
                                                            onBlur={validation.handleBlur} value={validation.values.description}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group color-theme"
                                                    onClick={toggleForePicker}
                                                >
                                                    <label>{t("ForeColor")}
                                                        <div className="color-btn btn-primary p-1" >
                                                            <img src={ColorPicker} onClick={() => OpenForeColorPicker()}></img>
                                                        </div>
                                                    </label>
                                                    <div className="input-group position-relative">
                                                        <Input name="color" className="form-control custom-form-control ps-5" placeholder={t("EnterHeaderColor")} type="text"

                                                            maxLength={50} value={forecolorId} onChange={(e) => {
                                                                setForeColorId(e.target.value)


                                                            }}

                                                        />
                                                        <div className="position-absolute rounded" style={{
                                                            backgroundColor: forecolorId,
                                                            width: 124,
                                                            height: 30,
                                                            top: 6,
                                                            right: 20,

                                                        }}
                                                        />
                                                    </div>
                                                    {isForeColor && !isBackColor ?
                                                        <div>
                                                            {showForePicker && (
                                                                <ChromePicker
                                                                    color={forecolorId}
                                                                    onChangeComplete={handleChangeComplete}
                                                                />
                                                            )}
                                                        </div> : ""
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group color-theme"
                                                    onClick={toggleBackPicker}
                                                >
                                                    <label>{t("BackColor")}
                                                        <div className="color-btn btn-primary p-1" >
                                                            <img src={ColorPicker} onClick={() => OpenBackColorPicker()}></img>
                                                        </div>
                                                    </label>
                                                    <div className="input-group position-relative">
                                                        <Input name="color" className="form-control custom-form-control ps-5" placeholder={t("EnterBackColor")} type="text"

                                                            maxLength={50} value={backcolorId} onChange={(e) => {
                                                                setBackColorId(e.target.value)


                                                            }}

                                                        />
                                                        <div className="position-absolute rounded" style={{
                                                            backgroundColor: backcolorId,
                                                            width: 124,
                                                            height: 30,
                                                            top: 6,
                                                            right: 20,

                                                        }}
                                                        />
                                                    </div>
                                                    {isBackColor && !isForeColor ?
                                                        <div>
                                                            {showBackPicker && (
                                                                <ChromePicker
                                                                    color={backcolorId}
                                                                    onChangeComplete={handleChangeBackColor}
                                                                />
                                                            )}
                                                        </div> : ""
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-6">

                                                <div className="form-checkbox ">
                                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                                        <label>
                                                            <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleActiveChange()} ></input> {t("Active")}</label> :

                                                        <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleActiveChange()} ></input>
                                                            {t("Active")}</label>}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-primary mr-3" >{pageButton}</button>
                                        <button className="btn btn-primary" type="button" onClick={handleClose}>
                                            {t("Cancel")}
                                        </button>
                                    </div>
                                </Form>
                            }
                        </Modal.Body>

                    </Modal>
                </>
            }
        </div>
        
    )

}

export default connect()(BannerIndex);
