import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../loader";
import Layout from "../Layout";
import Table from "antd/lib/table";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { fetchSupportGroupLogsdata } from "../../Redux/Api/fetch.api";
import { useHistory } from 'react-router-dom'
import { getsupportgroupdata } from '../../Redux/Actions/CallOrderActions';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Tooltip } from 'antd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import total_count from '../../assets/images/total-count.svg';
import { useTranslation } from "react-i18next";

const SupportGroupLogs = () => {
    const { t } = useTranslation('supportGruoupLog');
    const { supportId } = useParams();
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(5);
    const [historytable, setHistoryTable] = useState([]);
    const [logCount, setLogCount] = useState(0);
    const [groupName, setGroupName] = useState();
    const history = useHistory();
   
   
  //  const [totalCount, setTotalCount] = useState(5)
    const showPagination = totalItems > REACT_APP_DEFAULT_PAGESIZE;
    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    // Use the function to get the groupid
    const groupid = getQueryParam('groupid');
    console.log("Received groupid:", groupid);

    useEffect(() => {
        GetSupportGroupLogsDetails(groupid);
       
    }, []);

    const GetSupportGroupLogsDetails = (groupid) => {
        setLoading(true)
        fetchSupportGroupLogsdata({
            PageIndex: currentPage,
            PageSize: pageSize > REACT_APP_DEFAULT_PAGESIZE ? pageSize : REACT_APP_DEFAULT_PAGESIZE,
            GroupId: groupid
        })
            .then(response => {
               
                dispatch(getsupportgroupdata(response.data))
                setHistoryTable(response.data.data)
                setLogCount(response.data.data[0].totalCount)
                setGroupName(response.data.data[0].groupName)
                setTotalItems(response.data.data[0].totalCount);
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const handleBackRedirection = () => {
        history.push('/CallOrder');
    }

    const openloadreport = () => {
        GetSupportGroupLogsDetails();
    }

    const handlePageChange = (page, newPageSize) => {
        setCurrentPage(page);
        setPagesize(newPageSize);
    };

    function getInitials(name) {
        if (name) {
            const nameParts = name.split(' ');
            const initials = nameParts.map(part => part.charAt(0));
            const result = initials.join('');
            return result;
        }
    }

    const columns = [

        {
            title: t("SrNo"),
            dataIndex: "",
            render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,
        },

       

        {
            title: t("UserName"),
            dataIndex: "userName",
            render: (value, item, index) =>

            (<>
                <div className="d-flex align-items-center name-col-created">
                    {

                        item.userName ?
                            <p className="name-prefix assigned-name d-inline-block mb-0 mr-2">{getInitials(item.userName)}</p> :
                            <p className="name-prefix assigned-name d-inline-block mr-2 mb-0">UA</p>
                    }  <span>{item.userName}</span>
                </div>
            </>)
        },

        {
            title: t("SupportGroup"),
            dataIndex: "groupName",
        },

        {
            title: t("OrderId"),
            dataIndex: "orderId",
        },

         
        {
            title: t("AssignedDate"),
            dataIndex: "assignedDate",
            
        },

        {
            title: t("UpdatedBy"),
            dataIndex: "updatedBy",
            render: (value, item, index) =>

            (<>
                <div className="d-flex align-items-center name-col-created">
                    {

                        item.userName ?
                            <p className="name-prefix assigned-name d-inline-block mb-0 mr-2">{getInitials(item.updatedBy)}</p> :
                            <p className="name-prefix assigned-name d-inline-block mr-2 mb-0">UA</p>
                    }  <span>{item.updatedBy}</span>
                </div>
            </>)
        },

       

        {
            title: t("StatusMessage"),
            dataIndex: "statusMessage",
        },
    ]

    return (
        <div>
            <>
                {loading ? <Loader /> : ''}
                <Layout>
                    <div className="p-3">
                        <section className="Company_section common common-table py-3">
                            <Toaster />

                            <>

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex gap-3">
                                                <button className="backBTN" onClick={handleBackRedirection}>
                                                    <img src={Arrow_leftbrown} alt="moveBackward" />{t("Back")}
                                                </button>
                                                <div className="load-report ml-auto p-0">
                                                    <label>{t("LoadLogs")}</label>
                                                    <Tooltip title={t("Clicktoloadlogs")}>
                                                        <RestartAltIcon onClick={() => openloadreport()} style={{ color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-4 support_log_head">
                                            <h4 className="m-0">{t("SupportGroupLog")}</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <h6 className="m-0 d-flex align-items-center justify-content-center request_id">{t("GroupName")}: {groupName}</h6>

                                        </div>
                                      
                                        <div className="col-md-4">
                                            <p className="ml-md-auto mb-0"></p>
                                        </div>

                                    </div>


                                    <label className="text-right w-100 total_count"><span><img src={total_count} /></span>{t("TotalCount")} : {logCount}</label>
                                    <div className="Viewer-table index--table position-relative support-row-tb common-table mb-4">
                                        <Table
                                            className='table-responsive antd--detail-table1 support assgin-table'
                                            columns={columns}
                                            dataSource={historytable}
                                            pagination={showPagination ? {
                                                total: totalItems,
                                                pageSize: pageSize,
                                                current: currentPage,
                                                showSizeChanger: true,
                                                onChange: handlePageChange,
                                                pageSizeOptions: ["50", "100"],
                                            } : false}
                                        />
                                    </div>
                                </div>
                            </>

                        </section>
                    </div>
                </Layout>
            </>
        </div>
    )
}
export default connect()(SupportGroupLogs);