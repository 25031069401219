import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import { Tooltip } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllSessionLogs, fetchProjectDetails, fetchLogout, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getalllogs } from '../../Redux/Actions/UserActivityAction';
import { getallprojects } from '../../Redux/Actions/AccessibilityActions';
import logout from "../../assets/images/logoutUser.svg";
import refresh from "../../assets/images/refresh.svg";
import activeUser from "../../assets/images/active.svg";
import search from "../../assets/images/search.svg";
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { useTranslation } from "react-i18next";

const UserSessions = () => {
    const { t } = useTranslation('userSession');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;  
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [pageSize, setPagesize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [username, setUserName] = useState("")
    const [ipAddress, setIPAddress] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(5)
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')  
    const showPagination = totalCount > REACT_APP_DEFAULT_PAGESIZE;
    const [dropdownChange, setDropdownChange] = useState(false);
    const [allAppData, setAllAppData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const loggedInEmailId = localStorage.getItem('emailid');
    const [roleName, setRoleName] = useState(null);



    useEffect(() => {      
        getRoleDetails();
        getallapps();
    }, [])

    useEffect(() => {
        getAllSessionLogs(true)
    }, [refreshData])

    const getAllSessionLogs = (isReset = false, pageindex = 1, pagesize = REACT_APP_DEFAULT_PAGESIZE) => {
        
        setLoading(true)
        fetchAllSessionLogs({
            PageIndex: pageindex,
            PageSize: pageSize > REACT_APP_DEFAULT_PAGESIZE ? pageSize : pagesize,
            UserName: isReset ? "" : username,
            IPAddress: isReset ? "" : ipAddress,
            EmailId:  loggedInEmailId
           
        })
            .then(response => {
               
                dispatch(getalllogs(response.data))
                setTable(response.data.data)
                setTotalCount(response.data.count);
                setLoading(false)
            })
       
            .catch((err) => {
                console.log("Err: ", err);
            });
        handleCancel();
        //setTimeout(() => {
        //    setLoading(false)
        //}, 4000)
    }

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))              
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const logoutUser = (emailId, IsLogoutOrRefresh) => {
        //IsLogoutOrRefresh = true -- Refresh
        //IsLogoutOrRefresh = false -- Logout
        
        fetchLogout(emailId, IsLogoutOrRefresh, false)
            .then(response => {
                console.log(response)
                getAllSessionLogs(true)
            })
       
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handlePageChange = (page, newPageSize) => {
        setCurrentPage(page);
        setPagesize(newPageSize);
        getAllSessionLogs(false, page, newPageSize)
    };

    const getallapps = () => {
       
        setLoading(true)
        fetchProjectDetails()
            .then(response => {
                
                dispatch(getallprojects(response.data))
                setAllAppData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getFullDate = (date) => {
        const newDate = new Date(date);
        
        const returnDate =
            ((newDate.getMonth() > 8) ? (newDate.getMonth() + 1) : ('0' + (newDate.getMonth() + 1))) +
            '-' +
            ((newDate.getDate() > 9) ? newDate.getDate() : ('0' + newDate.getDate())) +
            '-' +
            newDate.getFullYear();
    
        const dateAndTime = date.split("T");
        const time = dateAndTime[1].split(":");
        const newtime = `${time[0]}:${time[1]}:${time[2].split('.')[0]}`; // Include seconds without milliseconds
    
        const hour = time[0] > 12 ? time[0] % 12 : time[0];
        const amPm = time[0] >= 12 ? 'PM' : 'AM';
        const newtime1 = `${hour}:${time[1]}:${time[2].split('.')[0]} ${amPm}`; // Include seconds without milliseconds
    
        return `${returnDate}, ${newtime1}`;
    };
    
    

    const SearchControl = async () => {
              
        getAllSessionLogs();
    }

    const handleCancel = () => {
        setDropdownChange(true);
        setTimeout(() => {
            setDropdownChange(false);
        }, 0);
    };

    const handleSearchClear = () => {
        
        setUserName('');
        setIPAddress('');    
        setRefreshData(prev => !prev); 
        handleCancel();
       
    };

    const refreshLogs = () => {
        setUserName('');
        setIPAddress('');
        setRefreshData(prev => !prev);       
    };


    const columns = [

        {            
             title: t("SrNo"),
             dataIndex: "",
             render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,        
        },

        {
            title: t("UserName"),
            dataIndex: "userName",
            className: "names-col",
            render: (text, record) => (
              <span>
                <img className="active-check" src={activeUser} /> {text}
              </span>
            ),
          },

        {
            title: t("IPAddress"),
            dataIndex: "ipAddress",
            className: "ip-col",
            render: (ipAddress) => <Tooltip title={ipAddress}>{ipAddress}</Tooltip>,
           

        },

        {
            title: t("LoginTime"),
            dataIndex: "executeTime",
            render: (executeTime) => getFullDate(executeTime),
           
        },

        {
            title: t("LastRefreshTime"),
            dataIndex: "lastRefreshTime",
            render: (refreshTime) => refreshTime ? getFullDate(refreshTime) : "",
        },
        

        // {
        //     title: "Logout Time",
        //     dataIndex: "logoutTime",
        //     render: (logoutTime) => logoutTime ? getFullDate(logoutTime) : "",
        // },

                {
                    title: t("Action"),
                    className: "sitebuttons",
            dataIndex: "",
                    render: (data, companyId) => (<>
                        <ul className="list-inline">
                            <li className="list-inline-item mr-0">
                        <button className="table-btn" >
                <img src={refresh} onClick={() => logoutUser(data.emailId, true)}></img>
                                </button>
                            </li>
                            <li className="list-inline-item mr-0">
                <button className="table-btn" >
                    <img src={logout} onClick={() => logoutUser(data.emailId, false)}></img>
                                </button>
                            </li>
                            </ul>
                        </>)
        }
    ]

    return (
        <div>      
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                    <Layout>
                        <section className="User_section common ">
                            <Toaster />
                            <div className="container logs-container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="m-0">{t("SessionLogs")}</h4>
                                    {<div className="dropdown flex1">
                                        <button
                                            className={[
                                                "filter-btn dropdown-toggle btn d-flex align-items-center",
                                                dropdownChange === true ? "hide" : "",
                                            ].join(" ")}
                                            type="button"
                                            id="dropdownFilterMenuButton1"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="false"
                                            aria-expanded="false"
                                        >
                                            <span>
                                                <img src={search} ></img> {t("Filter")}
                                            </span>
                                        </button>
                                        <ul
                                            className={[
                                                "dropdown-menu entries-dropdown-search",
                                                dropdownChange === true ? "hide" : "",
                                            ].join(" ")}
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <div className="search--tracking-col">
                                                        <label class="form-label">{t("UserName")}</label>
                                                        <input
                                                            className="form-control"
                                                            placeholder={t("EnterUserName")}
                                                            type="text"
                                                            value={username}
                                                            onChange={(e) => {
                                                                const inputText = e.target.value.trimLeft();
                                                                const cleanedText = inputText.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces

                                                                setUserName(cleanedText);
                                                                //setUserName(e.target.value.replace(/\s{2}/g, ''));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="search--asn-col">
                                                        <label class="form-label">{t("IPAddress")}</label>
                                                        <input
                                                            className="form-control"
                                                            placeholder={t("EnterIPAddress")}
                                                            type="text"
                                                            value={ipAddress}
                                                            onChange={(e) => {
                                                                const inputText = e.target.value.trimLeft();
                                                                setIPAddress(inputText);
                                                                //setCompanyName(e.target.value.replace(/\s{2}/g, ''));
                                                            }}
                                                        />
                                                    </div>
                                                </div>




                                                <div className="col-md-12 d-flex justify-content-end mt-3">
                                                    {username != "" || ipAddress != "" ?
                                                        <button
                                                            className="btn btn-primary ms-auto "
                                                            onClick={SearchControl}
                                                        >
                                                            {t("Search")}
                                                        </button> :

                                                        <button
                                                            className="btn btn-primary ms-auto "
                                                            onClick={SearchControl}
                                                            disabled
                                                        >
                                                            {t("Search")}
                                                        </button>
                                                    }
                                                    <button
                                                        className="btn btn-bordered pe-3 ps-3 ms-2"
                                                        onClick={handleCancel}
                                                    >
                                                        {t("Cancel")}
                                                    </button>
                                                    <button
                                                        className="btn btn-bordered pe-3 ps-3 ms-2"
                                                        onClick={handleSearchClear}
                                                    >
                                                        {t("Reset")}
                                                    </button>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>}
                                    <div>
                                        <button className="btn btn-primary refresh-btn ml-2" onClick={refreshLogs}>{t("Refresh")}</button>
                                    </div>

                                </div>
                                <div className="Viewer-table index--table position-relative common-table mb-4">

                                    <Table
                                        className='antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={showPagination ? {
                                            total: totalCount,
                                            pageSize: pageSize,
                                            current: currentPage,
                                            showSizeChanger: true,
                                            onChange: handlePageChange,
                                            pageSizeOptions: ["50", "100"],
                                        } : false}

                                    />
                                </div>
                            </div>
                        </section>
                    </Layout>
                </>
            }
          
        </div>
    )

}
export default connect()(UserSessions);

